import apiEndpoints from '@src/lib/apiEndpoints';

import apiRequest from './api';

export const syncUserData = async () => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.POWER_SCHOOL}`,
  });
};
