import apiEndpoints from '@src/lib/apiEndpoints';
import { FormControllerDataReturn } from '@src/lib/types';
import { MerchandiseAttributeKey } from '@src/Models/ProductCategory';
import { ResponseData } from '@src/screens/Staff/type';

import apiRequest from './api';
import { CreateAttributeValue, GetTableFilterProps, UpdateAttributeValue } from './type';

export const createAttribute = async (data: FormControllerDataReturn) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.MERCHANDISE_ATTRIBUTES}`,
    data: data,
  });
};

export const getAttributes = (params: GetTableFilterProps): Promise<ResponseData<MerchandiseAttributeKey>> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.MERCHANDISE_ATTRIBUTES}`,
    config: { params },
  });
};

export const deleteAttribute = async (id: string) => {
  return apiRequest({
    method: 'DELETE',
    url: `${apiEndpoints.MERCHANDISE_ATTRIBUTES}/${id}`,
  });
};

export const getAttributeById = async (id: string) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.MERCHANDISE_ATTRIBUTES}/${id}`,
  });
};

export const updateAttribute = async (id: string, data: FormControllerDataReturn) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.MERCHANDISE_ATTRIBUTES}/${id}`,
    data: data,
  });
};

export const updateAttributeValue = async (data: UpdateAttributeValue) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.MERCHANDISE_ATTRIBUTES}${apiEndpoints.UPDATE_ATTRIBUTE_VALUE}`,
    data: data,
  });
};

export const createAttributeValue = async (id: string, data: CreateAttributeValue) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.MERCHANDISE_ATTRIBUTES}${apiEndpoints.ADD_ATTRIBUTE_VALUE}/${id}`,
    data: data,
  });
};

export const deleteAttributeValue = async (id: string) => {
  return apiRequest({
    method: 'DELETE',
    url: `${apiEndpoints.MERCHANDISE_ATTRIBUTES}${apiEndpoints.REMOVE_ATTRIBUTE_VALUE}/${id}`,
  });
};
