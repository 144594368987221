import { ValueType } from '@src/components/FormController/type';
import apiEndpoints from '@src/lib/apiEndpoints';
import { FormControllerDataReturn } from '@src/lib/types';
import { ActivityMapping, ActivityMappingReturnProps } from '@src/Models/activityMapping';
import { Program } from '@src/Models/program';

import apiRequest from './api';
import { GetTableFilterProps } from './type';

export const getPrograms = async (params: GetTableFilterProps): Promise<{ data: Program[] }> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.PROGRAMS}`,
    config: { params },
  });
};

export const getProgramById = async (id: string): Promise<Program> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.PROGRAMS}/${id}`,
  });
};

export const updateProgram = async ({ data, id }: { id: string; data: Record<string, ValueType> }) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.PROGRAMS}/${id}`,
    data: { ...data },
  });
};

export const createProgram = async ({ data }: { data: Record<string, ValueType> }) => {
  return apiRequest({
    method: 'post',
    url: `${apiEndpoints.PROGRAMS}`,
    data: { ...data },
  });
};

export const softDeletePrograms = async ({ data }: { data: { id: string; active: boolean } }) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.PROGRAM_DELETE}`,
    data: { ...data },
  });
};

export const programCheckout = async ({ order_id }: { order_id: string }) => {
  return apiRequest({
    method: 'post',
    url: `${apiEndpoints.PROGRAM_PAYMENT}`,
    data: { order_id },
  });
};

// activity mapping apis
export const getActivityMapping = async (params: GetTableFilterProps): Promise<{ data: ActivityMapping[] }> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.MAPPING}`,
    config: { params },
  });
};

export const createActivityMapping = async (data: ActivityMappingReturnProps) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.CREATE_MAPPING}`,
    data: data,
  });
};

export const createGoogleSheetSync = async (data: FormControllerDataReturn) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.SYNC_GOOGLE_SHEET}`,
    data: data,
  });
};

export const getGoogleSheetSync = async (params: GetTableFilterProps) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.SYNC_GOOGLE_SHEET}`,
    config: { params },
  });
};

export const updateGoogleSheetSync = async (data: FormControllerDataReturn, id: string) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.SYNC_GOOGLE_SHEET}/${id}`,
    data: data,
  });
};

export const updateActivityMapping = async (data: ActivityMappingReturnProps, id: string) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.CREATE_MAPPING}/${id}`,
    data: data,
  });
};

export const getActivityMappingByMappingId = async (id: string) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.CREATE_MAPPING}/${id}`,
  });
};

export const deleteActivityMapping = async (id: string) => {
  return apiRequest({
    method: 'DELETE',
    url: `${apiEndpoints.CREATE_MAPPING}/${id}`,
  });
};

export const deleteActivityMappingList = async (id: string) => {
  return apiRequest({
    method: 'DELETE',
    url: `${apiEndpoints.MAPPING}/${id}`,
  });
};

export const getActivityMappingById = async (program_id: string): Promise<{ data: ActivityMapping }> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.MAPPING}`,
    config: { params: { program_id } },
  });
};

export const getWaitingList = async (params: GetTableFilterProps) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.WAITING_LIST}`,
    config: { params },
  });
};

export const manuallyAssign = async (id: string) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.MANUALLY_ASSIGN}/${id}`,
  });
};
