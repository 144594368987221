import apiEndpoints from '@src/lib/apiEndpoints';
import { FormControllerDataReturn } from '@src/lib/types';
import { Tag } from '@src/Models/tags';
import { ResponseData } from '@src/screens/Staff/type';

import apiRequest from './api';
import { GetTableFilterProps } from './type';

export const getTags = (params: GetTableFilterProps): Promise<ResponseData<Tag[]>> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.TAGS}`,
    config: { params },
  });
};

export const createTag = async (data: FormControllerDataReturn) => {
  return apiRequest({
    method: 'POST',
    url: apiEndpoints.TAGS,
    data,
  });
};
