import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';

const StyledMenuItem = styled(MenuItem)`
  &:hover {
    background-color: ${props => props.theme.colors.ashGray};
  }
`;

export interface MenuOption {
  label: string;
  key: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string };
  onClick: (label: string) => void;
}

interface MenuProps extends PropsWithChildren {
  menuID?: string;
  options: MenuOption[];
}

const CustomMenu: React.FC<MenuProps> = ({ options, children, menuID = 'custom-menu' }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton aria-controls={menuID} aria-haspopup='true' onClick={handleClick}>
        {children}
      </IconButton>
      <Menu id={menuID} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {options.map(({ icon: Icon, label, onClick, key }, index) => (
          <StyledMenuItem
            key={`${key}=${index}`}
            onClick={() => {
              onClick(key);
              handleClose();
            }}
          >
            {Icon ? <Icon fontSize='small' style={{ marginRight: 8 }} /> : null}
            {label}
          </StyledMenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default CustomMenu;
