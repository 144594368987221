import apiEndpoints from '@src/lib/apiEndpoints';
import { OrganizationSettings } from '@src/Models/organizationSettings';

import apiRequest from './api';

export const getOrganizationSettings = (): Promise<{ data: OrganizationSettings }> => {
  return apiRequest({
    method: 'GET',
    url: apiEndpoints.ORGANIZATION_SETTINGS,
  });
};

export const updateOrganizationSettings = (data: OrganizationSettings): Promise<{ data: OrganizationSettings }> => {
  return apiRequest({
    method: 'PUT',
    url: apiEndpoints.ORGANIZATION_SETTINGS_UPDATE,
    data,
  });
};

export const resetPoSerial = () => {
  return apiRequest({
    method: 'GET',
    url: apiEndpoints.RESET_PO_SERIAL,
  });
};
