import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Close as CloseIcon, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { AuthContext, HeadersType } from '@src/contexts/AuthContextProvider';
import { DrawerList, HeaderList } from '@src/lib/constants';
import { DrawerItem } from '@src/lib/types';

import { DrawerHeader, DrawerItemButton, StyledLogo, SubHeaderButton } from './styles';

interface TemporaryDrawerProps {
  isOpen: boolean;
  toggleDrawer: (newOpen: boolean) => () => void;
}

interface SideBarDrawerListProps {
  headers: HeadersType;
  toggleDrawer: (newOpen: boolean) => () => void;
}

interface SubHeadersProps {
  subHeaders: string[];
  currentModule: DrawerItem;
}

const TemporaryDrawer = ({ isOpen, toggleDrawer }: TemporaryDrawerProps) => {
  const { headers } = useContext(AuthContext);

  return (
    <Drawer open={isOpen} onClose={toggleDrawer(false)}>
      <DrawerHeader>
        <StyledLogo src='/logo.svg' alt='logo' />
        <IconButton onClick={toggleDrawer(false)}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <SideBarDrawer headers={headers} toggleDrawer={toggleDrawer} />
    </Drawer>
  );
};

const SideBarDrawer = ({ headers, toggleDrawer }: SideBarDrawerListProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openSubHeaders, setOpenSubHeaders] = useState<{ [key: string]: boolean }>({});

  const handleToggleSubHeaders = (module: string) => {
    setOpenSubHeaders(prevState => ({
      ...prevState,
      [module]: !prevState[module],
    }));
  };

  const handleNavigate = (selectedModule: DrawerItem) => {
    const selectedHeader = HeaderList({ headers, selectedModule }) || ([] as string[]);
    navigate(`/${selectedModule}/${selectedHeader[0] || ''}`);
    toggleDrawer(false);
  };

  return (
    <Box sx={{ width: 250 }} role='presentation' onClick={toggleDrawer(false)}>
      <List>
        {DrawerList.map(({ text, Icon }, index) => {
          if (!headers || !(headers === 'super_admin' || headers[text])) return null;

          const subHeaders = HeaderList({ headers, selectedModule: text }) || [];

          return (
            <div key={`${text}-${index}`}>
              <ListItem disablePadding>
                <DrawerItemButton
                  onClick={e => {
                    e.stopPropagation();
                    handleNavigate(text);
                  }}
                >
                  <div>
                    <div>
                      <ListItemIcon>
                        <Icon />
                      </ListItemIcon>
                      <ListItemText primary={t(text)} />
                    </div>
                    {subHeaders.length > 0 && (
                      <div>
                        <IconButton
                          onClick={e => {
                            e.stopPropagation();
                            subHeaders.length && handleToggleSubHeaders(text);
                          }}
                        >
                          {openSubHeaders[text] ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                      </div>
                    )}
                  </div>
                </DrawerItemButton>
              </ListItem>
              <Collapse in={openSubHeaders[text]} timeout='auto' unmountOnExit>
                <SubHeaders subHeaders={subHeaders} currentModule={text} />
              </Collapse>
            </div>
          );
        })}
      </List>
    </Box>
  );
};

const SubHeaders = ({ subHeaders, currentModule }: SubHeadersProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubHeaderClick = (subHeader: string) => {
    navigate(`/${currentModule}/${subHeader}`);
  };

  return (
    <div>
      {subHeaders.map((text, index) => (
        <ListItem
          key={`${text}-${index}`}
          disablePadding
          onClick={e => {
            e.stopPropagation();
            handleSubHeaderClick(text);
          }}
        >
          <SubHeaderButton>
            <ListItemText primary={t(text)} />
          </SubHeaderButton>
        </ListItem>
      ))}
    </div>
  );
};

export default TemporaryDrawer;
