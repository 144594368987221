import apiEndpoints from '@src/lib/apiEndpoints';

import apiRequest from './api';

export interface DownloadDaywiseProgramScheduleProps {
  program_id: string;
  day: string;
}

export const downloadDaywiseProgramSchedule = async (params: DownloadDaywiseProgramScheduleProps) => {
  return apiRequest({
    method: 'get',
    url: `${apiEndpoints.DAYWISE_PROGRAM_SCHEDULE}`,
    config: { params, responseType: 'blob' },
  });
};
