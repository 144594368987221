import apiEndpoints from '@src/lib/apiEndpoints';
import { ScheduleProps } from '@src/lib/types';

import apiRequest from './api';

export const getSchedules = async (params: ScheduleProps) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.SCHEDULE}`,
    config: { params },
  });
};

export const getSchedulesFilterList = async (params: ScheduleProps) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.SCHEDULE_LIST}`,
    config: { params },
  });
};

export const getOffDays = async () => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.OFF_DAYS}`,
  });
};
