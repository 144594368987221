import { lazy } from 'react';

import { AsaMasterSubCategory, AsaSubRoutes, RouteActions, Routes } from '@src/lib/constants';
import { RoutesConfigType } from '@src/lib/types';

const GoogleSheetSync = lazy(() => import('@src/screens/asa/GoogleSheetSync/create'));
const GoogleSheetSyncList = lazy(() => import('@src/screens/asa/GoogleSheetSync'));
const AsaSubmissions = lazy(() => import('@src/screens/asa/Submissions'));
const StudentActivityListing = lazy(() => import('@src/screens/asa/StudentActivityListing'));
const WaitingList = lazy(() => import('@src/screens/asa/WaitingList'));
const ManageSessionCreateEdit = lazy(() => import('@src/screens/asa/Master/Session/CreateEditLayout'));
const ManageSeasonCreateEdit = lazy(() => import('@src/screens/asa/Master/Season/CreateEditLayout'));
const SessionSeasonCreateEDit = lazy(() => import('@src/screens/asa/Master/Mapping/CreateEditLayout'));
const AsaScheduling = lazy(() => import('@src/screens/asa/Scheduling'));
const AsaProgram = lazy(() => import('@src/screens/asa/Programs'));
const ProgramDetailView = lazy(() => import('../screens/asa/Programs/detailsView'));
const CreateEditFormPrograms = lazy(() => import('../screens/asa/Programs/CreateEditForm'));
const CreateEditFormActivity = lazy(() => import('@src/screens/asa/Activity/CreateEditForm'));
const AsaActivities = lazy(() => import('@src/screens/asa/Activity'));
const AsaActivityDetails = lazy(() => import('@src/screens/asa/Activity/detailsView'));
const AsaActivityMapping = lazy(() => import('@src/screens/asa/Mapping'));
const ActivityMappingCreate = lazy(() => import('@src/screens/asa/Mapping/create'));
const ActivityMappingEdit = lazy(() => import('@src/screens/asa/Mapping/edit'));
const ActivityMappingDetailView = lazy(() => import('@src/screens/asa/Mapping/detailsView'));
const ASAMaster = lazy(() => import('@src/screens/asa/Master'));
const SignUp = lazy(() => import('@src/screens/asa/signUp'));
const CreateEditCategory = lazy(() => import('@src/components/Category/CreateEditLayout'));
const ThankYouPage = lazy(() => import('@src/screens/ThankYou'));
const ManageHomeTeacher = lazy(() => import('@src/screens/ManageHomeTeacher'));
const ActivitySchedulingList = lazy(() => import('@src/screens/asa/Scheduling/studentsList'));

const asaRoutes: Array<RoutesConfigType> = [
  {
    path: Routes.ASA + AsaSubRoutes.SIGN_UP,
    component: SignUp,
    layout: 'private',
    isBreadcrumb: false,
  },
  {
    path: Routes.ASA + AsaSubRoutes.MASTER + AsaMasterSubCategory.CATEGORY + RouteActions.CREATE,
    component: CreateEditCategory,
    layout: 'private',
  },
  {
    path: Routes.ASA + AsaSubRoutes.MASTER + AsaMasterSubCategory.CATEGORY + RouteActions.EDIT + '/:id',
    component: CreateEditCategory,
    layout: 'private',
  },
  { path: Routes.ASA + AsaSubRoutes.PROGRAMS, component: AsaProgram, layout: 'private' },
  {
    path: Routes.ASA + AsaSubRoutes.PROGRAMS + '/:id',
    component: ProgramDetailView,
    layout: 'private',
  },
  {
    path: Routes.ASA + AsaSubRoutes.MASTER + AsaMasterSubCategory.SESSION,
    component: ASAMaster,
    layout: 'private',
  },
  {
    path: Routes.ASA + AsaSubRoutes.MASTER + AsaMasterSubCategory.SEASON,
    component: ASAMaster,
    layout: 'private',
  },
  {
    path: Routes.ASA + AsaSubRoutes.MASTER + AsaMasterSubCategory.CATEGORY,
    component: ASAMaster,
    layout: 'private',
  },
  {
    path: Routes.ASA + AsaSubRoutes.MASTER + AsaMasterSubCategory.MAPPING,
    component: ASAMaster,
    layout: 'private',
  },
  {
    path: Routes.ASA + AsaSubRoutes.MASTER + AsaMasterSubCategory.SESSION + RouteActions.CREATE,
    component: ManageSessionCreateEdit,
    layout: 'private',
  },
  {
    path: Routes.ASA + AsaSubRoutes.MASTER + AsaMasterSubCategory.SESSION + RouteActions.EDIT + '/:id',
    component: ManageSessionCreateEdit,
    layout: 'private',
  },
  {
    path: Routes.ASA + AsaSubRoutes.MASTER + AsaMasterSubCategory.SEASON + RouteActions.CREATE,
    component: ManageSeasonCreateEdit,
    layout: 'private',
  },
  {
    path: Routes.ASA + AsaSubRoutes.MASTER + AsaMasterSubCategory.SEASON + RouteActions.EDIT + '/:id',
    component: ManageSeasonCreateEdit,
    layout: 'private',
  },
  {
    path: Routes.ASA + AsaSubRoutes.MASTER + AsaMasterSubCategory.MAPPING + RouteActions.CREATE,
    component: SessionSeasonCreateEDit,
    layout: 'private',
  },
  {
    path: Routes.ASA + AsaSubRoutes.MASTER + AsaMasterSubCategory.MAPPING + RouteActions.EDIT + '/:id',
    component: SessionSeasonCreateEDit,
    layout: 'private',
  },
  {
    path: Routes.ASA + AsaSubRoutes.PROGRAMS + RouteActions.EDIT + '/:id',
    component: CreateEditFormPrograms,
    layout: 'private',
  },
  {
    path: Routes.ASA + AsaSubRoutes.PROGRAMS + RouteActions.CREATE,
    component: CreateEditFormPrograms,
    layout: 'private',
  },
  {
    path: Routes.ASA + AsaSubRoutes.ACTIVITY + RouteActions.CREATE,
    component: CreateEditFormActivity,
    layout: 'private',
  },
  { path: Routes.ASA + AsaSubRoutes.ACTIVITY, component: AsaActivities, layout: 'private' },
  {
    path: Routes.ASA + AsaSubRoutes.ACTIVITY + RouteActions.EDIT + '/:id',
    component: CreateEditFormActivity,
    layout: 'private',
  },
  {
    path: Routes.ASA + AsaSubRoutes.ACTIVITY + '/:id',
    component: AsaActivityDetails,
    layout: 'private',
  },
  { path: Routes.ASA + AsaSubRoutes.MAPPING, component: AsaActivityMapping, layout: 'private' },
  { path: Routes.ASA + AsaSubRoutes.MAPPING + '/:id', component: ActivityMappingDetailView, layout: 'private' },
  {
    path: Routes.ASA + AsaSubRoutes.MAPPING + RouteActions.CREATE,
    layout: 'private',
    component: ActivityMappingCreate,
  },
  {
    path: Routes.ASA + AsaSubRoutes.MAPPING + RouteActions.EDIT + '/:id',
    layout: 'private',
    component: ActivityMappingEdit,
  },
  {
    path: Routes.ASA + AsaSubRoutes.SUBMISSIONS,
    layout: 'private',
    component: AsaSubmissions,
  },
  {
    path: Routes.ASA + AsaSubRoutes.SCHEDULING,
    layout: 'private',
    component: AsaScheduling,
  },
  {
    path: Routes.ASA + AsaSubRoutes.SCHEDULING + '/:id',
    layout: 'private',
    component: ActivitySchedulingList,
    isBreadcrumb: false,
  },
  {
    path: Routes.ASA + AsaSubRoutes.HOME_ROOM,
    layout: 'private',
    component: ManageHomeTeacher,
  },
  {
    path: Routes.PAYMENT + '/:id',
    isStyle: false,
    layout: 'public',
    component: ThankYouPage,
  },
  {
    path: Routes.ASA + AsaSubRoutes.STUDENT_SCHEDULE,
    layout: 'private',
    component: StudentActivityListing,
  },
  {
    path: Routes.ASA + AsaSubRoutes.WAITING_LIST,
    layout: 'private',
    component: WaitingList,
  },
  {
    path: Routes.ASA + AsaSubRoutes.GOOGLE_SHEET_SYNC + RouteActions.CREATE,
    layout: 'private',
    component: GoogleSheetSync,
  },
  {
    path: Routes.ASA + AsaSubRoutes.GOOGLE_SHEET_SYNC,
    layout: 'private',
    component: GoogleSheetSyncList,
  },
];

export default asaRoutes;
