/**
 * This is the name of the cookie that needs to be retrieved.
 * @param name
 */

export function getCookie(name: string) {
  const cookieArr = document.cookie.split(';');

  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].split('=');

    if (name === `${cookiePair[0].trim()}`) {
      return decodeURIComponent(cookiePair[1]);
    }
  }

  return null;
}
