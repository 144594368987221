import { useTranslation } from 'react-i18next';

import { Dialog } from '@mui/material';

import { StyledLoaderContainer, StyledLoadingText } from './style';
import Props from './types';

import './loader.css';

const Loader = ({ message }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Dialog open>
      <StyledLoaderContainer>
        <div className='loader'></div>
        <StyledLoadingText>{(message && message) || `${t('loading')}...`}</StyledLoadingText>
      </StyledLoaderContainer>
    </Dialog>
  );
};

export default Loader;
