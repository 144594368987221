import { createContext } from 'react';

import { Modes, ModeType } from '@src/lib/styles/theme';
export type SnackType = { type: 'success' | 'error'; message: string } | undefined;

export type LoadingType = 'modal' | 'table' | false;

interface GlobalUIContextProps {
  mode: ModeType;
  loading: LoadingType;
  setMode: (val: Modes) => void;
  setLoading: (val: LoadingType) => void;
  setSnackValue: (val: SnackType) => void;
  snackValue: SnackType;
}

export const GlobalUIContext = createContext<GlobalUIContextProps>({
  mode: Modes.LIGHT,
  loading: false,
  snackValue: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSnackValue: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setMode: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLoading: () => {},
});
