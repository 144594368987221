import axios from 'axios';

import apiEndpoints from '@src/lib/apiEndpoints';

import apiRequest from './api';
import { FileUploadType } from './type';

export const generateS3Url = (data: FileUploadType) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.UPLOAD_FILES}`,
    data,
  });
};

export const uploadFile = async (url: string, file: File) => {
  try {
    const response = await axios.put(url, file);
    return response;
  } catch (error) {
    console.error(error);
  }
};
