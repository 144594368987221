import apiEndpoints from '@src/lib/apiEndpoints';
import { Parent } from '@src/Models/parent';

import apiRequest from './api';
import { GetTableFilterProps, UploadChunkProps } from './type';

export const getParents = (params: GetTableFilterProps): Promise<Parent[]> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.PARENTS}`,
    config: { params },
  });
};

export const getParentByID = async (id: string): Promise<Parent> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.PARENTS}/${id}`,
  });
};

export const photoUpload = async (file: FormData, params: UploadChunkProps) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.PHOTO_UPLOAD}`,
    data: file,
    contentType: 'multipart/form-data',
    config: { params },
  });
};
