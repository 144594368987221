import apiEndpoints from '@src/lib/apiEndpoints';

import apiRequest from './api';

export interface DownloadWaitingActivityListProps {
  program_id: string;
}

export const downloadWaitingActivityList = async (params: DownloadWaitingActivityListProps) => {
  return apiRequest({
    method: 'get',
    url: `${apiEndpoints.WAITING_ACTIVITY_LIST}`,
    config: { params, responseType: 'blob' },
  });
};
