import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { setUserDetailExternally } from '@src/contexts/AuthContextProvider';
import apiEndpoints from '@src/lib/apiEndpoints';
import { Routes } from '@src/lib/constants';
import { deleteCookies } from '@src/utils/deleteCookies';
import { getCookie } from '@src/utils/getCookie';
import { setCookie } from '@src/utils/setCookie';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const axiosInstance: AxiosInstance = axios.create({
  baseURL: BASE_URL,
});

let isAlreadyFetchingAccessToken = false;

export async function refreshAccessToken() {
  try {
    const response = await axiosInstance.get(apiEndpoints.REFRESH_TOKEN, {
      headers: {
        Authorization: `Bearer ${getCookie('refresh_token')}`,
      },
    });

    const { access_token, access_token_expires_in } = response.data;
    setCookie('access_token', access_token, access_token_expires_in);

    const userDetailsResponse = await axiosInstance.get(apiEndpoints.SELF, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });

    setUserDetailExternally(userDetailsResponse.data);

    return access_token;
  } catch (error) {
    console.error('Error refreshing token', error);
    deleteCookies(['access_token', 'refresh_token', 'remember_me']);
    localStorage.removeItem('mode');
    window.location.href = Routes.LOGIN;
    return Promise.reject(error);
  }
}

axiosInstance.interceptors.request.use(
  config => {
    const token = getCookie('access_token');

    if (!config.headers.Authorization && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: AxiosError) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !isAlreadyFetchingAccessToken && originalRequest) {
      isAlreadyFetchingAccessToken = true;

      try {
        const newAccessToken = await refreshAccessToken();
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      } finally {
        isAlreadyFetchingAccessToken = false;
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
