export type Monochrome = 'black' | 'white';
export type PrimaryPalette = 'iconColors' | 'textColors' | 'bgColors' | 'linearColors';

export interface RawColorPalette {
  indigo: string;
  carrotOrange: string;
  limeGreen: string;
  redOrange: string;
  navyBlue: string;
  cinnabar: string;
  appleGreen: string;
  midnightBlue: string;
  lightGray: string;
  amber: string;
  brightCyan: string;
  alabaster: string;
  platinum: string;
  spanishGray: string;
  babyBlue: string;
  royalBlue: string;
  persianIndigo: string;
  lightViolet: string;
  lavenderBlue: string;
  lavenderMist: string;
  orange: string;
  babyPurple: string;
  lightAqua: string;
  lightGreen: string;
  lightBlue: string;
  skyBlue: string;
  ashGray: string;
  brightBlue: string;
  deepBlue: string;
  paleGray: string;
  shadeGray: string;
  offWhite: string;
  jetBlack: string;
  blazeOrange: string;
  blueBell: string;
  inkShade: string;
  dimGray: string;
  darkGray: string;
  gray: string;
  greenSuccess: string;
  orangeDark: string;
  mistGray: string;
  black: string;
  white: string;
  lightGrayBg: string;
  mediumGrayText: string;
  lightGreenBg: string;
  darkGreenText: string;
  lightOrangeBg: string;
  brightOrangeText: string;
  lightBlueBg: string;
  darkBlueText: string;
  mediumGray: string;
  darkRed: string;
  lightPeach: string;
  coolGrayText: string;
  deepGrayBorder: string;
  coolDeepGrayText: string;
  slateGray: string;
  linearColors: {
    mangoTango: string;
    indigoDye: string;
    aliceBlue: string;
    oceanBlue: string;
  };
}

export const lightModeColors: RawColorPalette = {
  indigo: '#5157AB',
  coolDeepGrayText: '#999999',
  coolGrayText: '#828282',
  deepGrayBorder: '#D9D9D9',
  carrotOrange: '#EE8629',
  limeGreen: '#66CF35',
  redOrange: '#D33B2B',
  navyBlue: '#2e3192',
  cinnabar: '#F24423',
  appleGreen: '#55AE22',
  midnightBlue: '#12133A',
  lightGray: '#D3D3D3',
  amber: '#ff9900',
  brightCyan: '#00a3cc',
  alabaster: '#F4F6F6',
  platinum: '#ECECEC',
  spanishGray: '#8A8A8A',
  babyBlue: '#e7f1fe',
  royalBlue: '#2e3192',
  persianIndigo: '#300073',
  lightViolet: '#9ea0e0',
  lavenderBlue: '#ffffff',
  lavenderMist: '#4d94ff',
  orange: '#D97415',
  babyPurple: '#bebfe0',
  lightAqua: '#f0f5f5',
  lightGreen: '#90EE90',
  lightBlue: '#add8e6',
  skyBlue: '#99ccff',
  ashGray: '#cccccc',
  brightBlue: '#007bff',
  deepBlue: '#0056b3',
  paleGray: '#f2f2f2',
  shadeGray: '#f1f1f1',
  offWhite: '#F0F0F0',
  linearColors: {
    mangoTango: 'linear-gradient(#F8A960, #F08D30)',
    indigoDye: 'linear-gradient(#585DAF, #333B92)',
    aliceBlue: 'linear-gradient(#d2e4fe, #ffffff)',
    oceanBlue: 'linear-gradient(to bottom, #a4ccf4 10%, #d1e6fa 60%)',
  },
  greenSuccess: '#128A33',
  orangeDark: '#8A5312',
  jetBlack: '#333333',
  blazeOrange: '#EE8629',
  blueBell: '#5157AB',
  inkShade: '#000000',
  dimGray: '#666666',
  darkGray: '	#A9A9A9',
  gray: '#777777',
  mistGray: '#EEEEEE',
  black: '#1A1A1A',
  white: '#FFFFFF',
  lightGrayBg: '#F3F4F6',
  mediumGrayText: '#6B7280',
  lightGreenBg: '#CDF4DD',
  darkGreenText: '#188544',
  lightOrangeBg: '#FFECE1',
  brightOrangeText: '#FF5C00',
  lightBlueBg: '#DEE8FF',
  darkBlueText: '#5C69F8',
  mediumGray: '#757575',
  darkRed: '#d32f2f',
  lightPeach: '#F1D9CA',
  slateGray: '#6F6C90',
};

export const darkModeColors: RawColorPalette = {
  indigo: '#5157AB',
  coolDeepGrayText: '#999999',
  coolGrayText: '#828282',
  deepGrayBorder: '#D9D9D9',
  carrotOrange: '#EE8629',
  limeGreen: '#66CF35',
  redOrange: '#D33B2B',
  navyBlue: '#2e3192',
  cinnabar: '#F24423',
  appleGreen: '#55AE22',
  midnightBlue: '#12133A',
  lightGray: '#D3D3D3',
  amber: '#ff9900',
  brightCyan: '#00a3cc',
  alabaster: '#F4F6F6',
  platinum: '#ECECEC',
  spanishGray: '#8A8A8A',
  babyBlue: '#e7f1fe',
  royalBlue: '#2e3192',
  persianIndigo: '#300073',
  lightViolet: '#9ea0e0',
  lavenderBlue: '#8b8dda',
  lavenderMist: '#d8d9f3',
  orange: '#D97415',
  babyPurple: '#bebfe0',
  lightAqua: '#f0f5f5',
  lightGreen: '#90EE90',
  lightBlue: '#5155c8',
  skyBlue: '#99ccff',
  ashGray: '#cccccc',
  brightBlue: '#007bff',
  deepBlue: '#0056b3',
  paleGray: '#f2f2f2',
  shadeGray: '#f1f1f1',
  offWhite: '#F0F0F0',
  linearColors: {
    mangoTango: 'linear-gradient(#F8A960, #F08D30)',
    indigoDye: 'linear-gradient(#585DAF, #333B92)',
    aliceBlue: 'linear-gradient(#d2e4fe, #ffffff)',
    oceanBlue: 'linear-gradient(to bottom, #a4ccf4 10%, #d1e6fa 60%)',
  },
  greenSuccess: '#128A33',
  orangeDark: '#8A5312',
  jetBlack: '#333333',
  blazeOrange: '#EE8629',
  blueBell: '#5157AB',
  inkShade: '#FFFFFF',
  dimGray: '#666666',
  darkGray: '	#A9A9A9',
  gray: '#777777',
  mistGray: '#EEEEEE',
  black: '#1A1A1A',
  white: '#FFFFFF',
  lightGrayBg: '#F3F4F6',
  mediumGrayText: '#6B7280',
  lightGreenBg: '#CDF4DD',
  darkGreenText: '#188544',
  lightOrangeBg: '#FFECE1',
  brightOrangeText: '#FF5C00',
  lightBlueBg: '#DEE8FF',
  darkBlueText: '#5C69F8',
  mediumGray: '#757575',
  darkRed: '#d32f2f',
  lightPeach: '#F1D9CA',
  slateGray: '#6F6C90',
};

export type AllColorPalette = RawColorPalette;
