import styled from 'styled-components';

export const UnauthorizedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.paleGray};
  text-align: center;
`;

export const MessageBox = styled.div`
  background: white;
  padding: 2rem 4rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const Heading = styled.h1`
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.jetBlack};
`;

export const Subheading = styled.h2`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.dimGray};
  margin-top: 0.5rem;
`;

export const Description = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray};
  margin-top: 1rem;
`;

export const BackButton = styled.button`
  margin-top: 2rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.brightBlue};
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.deepBlue};
  }
`;
