export enum Breakpoints {
  MOBILE_SMALL = '@media screen and (max-width: 320px)',
  MOBILE = '@media screen and (max-width: 480px)',
  TABLET_SMALL = '@media screen and (max-width: 650px)',
  TABLET = '@media screen and (max-width: 890px)',
  DESKTOP_SMALL = '@media screen and (max-width: 1024px)',
  DESKTOP = '@media screen and (max-width: 1280px)',
  DESKTOP_LARGE = '@media screen and (max-width: 1440px)',
  DESKTOP_XLARGE = '@media screen and (max-width: 1920px)',
}
