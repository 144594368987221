import apiEndpoints from '@src/lib/apiEndpoints';

import apiRequest from './api';

export interface DownloadWeeklyScheduleProps {
  student_ids?: string;
}

export const downloadWeeklySchedule = async (params: DownloadWeeklyScheduleProps) => {
  return apiRequest({
    method: 'get',
    url: `${apiEndpoints.WEEKLY_SCHEDULE}`,
    config: { params, responseType: 'blob' },
  });
};
