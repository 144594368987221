import { lazy } from 'react';

import { Routes } from '@src/lib/constants';
import { RoutesConfigType } from '@src/lib/types';

const Dashboard = lazy(() => import('@src/screens/Dashboard'));
const PhotoUpload = lazy(() => import('@src/screens/PhotoUpload'));

const homeRoutes: Array<RoutesConfigType> = [
  {
    path: Routes.DASHBOARD,
    component: Dashboard,
    isBreadcrumb: false,
    layout: 'private',
  },
  {
    path: '/upload',
    component: PhotoUpload,
    layout: 'private',
  },
];

export default homeRoutes;
