import { Suspense, useCallback } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import Loader from '@src/components/Loader';
import PrivateRoute from '@src/layouts/PrivateRoute';
import PublicRoute from '@src/layouts/PublicRoute';
import { checkPermissions } from '@src/lib/helper';
import { permissionEnum, RoutesConfigType } from '@src/lib/types';
import routesConfig from '@src/routes/routesConfig';

import RestrictedRoute from './layouts/RestrictedRoute';
import { RootRestrictedRoute } from './layouts/RootRestrictedRoute';
import { redirectionRoutes } from './routes/redirectionRoutes';

const App = () => {
  const checkPermissionMethod = useCallback(
    (permissions: Array<permissionEnum>): boolean => {
      return permissions.some(permissionName => checkPermissions(permissionName));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(['Create_user'])] //get user permissions from Backend and store in store.
  );

  const renderLayout = (config: RoutesConfigType): JSX.Element => {
    const Component = config.component;

    switch (config.layout) {
      case 'private':
        return <Route key='private' path={config.path} element={<PrivateRoute {...config} />} />;
      case 'public':
        return <Route key='public' path={config.path} element={<PublicRoute {...config} />} />;
      case 'restricted':
        return <Route key='restricted' path={config.path} element={<RestrictedRoute {...config} />} />;
      default:
        return (
          <Route
            key='default'
            path={config.path}
            element={<PrivateRoute component={Component} isStyle={config.isStyle} />}
          />
        );
    }
  };

  const renderRoute = (config: RoutesConfigType): JSX.Element | undefined => {
    if (config.allowedPermission) {
      if (checkPermissionMethod(config.allowedPermission)) {
        return renderLayout(config);
      }

      return undefined;
    } else {
      return renderLayout(config);
    }
  };

  const renderRedirectionRoutes = redirectionRoutes.map(({ route, redirectionRoute }) => (
    <Route path={route} element={<Navigate to={route + redirectionRoute} replace />} />
  ));

  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Routes>
          {routesConfig.map(config => renderRoute(config))}
          <Route path='/' element={<RootRestrictedRoute />} />
          <Route path='*' element={<RootRestrictedRoute />} />
          {renderRedirectionRoutes}
        </Routes>
      </Router>
    </Suspense>
  );
};

export default App;
