import apiEndpoints from '@src/lib/apiEndpoints';

import apiRequest from './api';

export interface DownloadProgramSubmissionsProps {
  program_id: string;
}

export const downloadProgramSubmissions = async (params: DownloadProgramSubmissionsProps) => {
  return apiRequest({
    method: 'get',
    url: `${apiEndpoints.PROGRAM_SUBMISSION}`,
    config: { params, responseType: 'blob' },
  });
};
