import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import { Languages } from '@src/lib/constants';
import { Language } from '@src/lib/types';

type ResourceObject = {
  [key in Language]: {
    translations: Record<string, unknown>;
  };
};

const resources = Languages.reduce((acc, lang) => {
  acc[lang] = {
    translations: require(`./locales/${lang}/translations.json`),
  };
  return acc;
}, {} as ResourceObject);

i18n.use(initReactI18next).init({
  fallbackLng: Language.EN,
  lng: Language.EN,
  resources,
  ns: ['translations'],
  defaultNS: 'translations',
});

i18n.languages = Languages;

export default i18n;
