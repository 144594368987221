import { LoginResponse } from '@src/contexts/type';
import apiEndpoints from '@src/lib/apiEndpoints';
import { getCookie } from '@src/utils/getCookie';

import apiRequest from './api';

export const signUpWithGoogle = async (code: string): Promise<LoginResponse> => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.SIGN_UP_GOOGLE}`,
    data: { code },
  });
};

export const adminLogin = async (adminDetails: { username: string; password: string }): Promise<LoginResponse> => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.ADMIN_LOGIN}`,
    data: adminDetails,
  });
};

export const verify2fa = async (otpDetails: { otp: string; mfatoken: string }): Promise<LoginResponse> => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.VERIFY_2FA}`,
    data: otpDetails,
  });
};

export const getUserDetails = async (token?: string) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.SELF}`,
    ...(token ? { config: { headers: { Authorization: `Bearer ${token}` } } } : {}),
  });
};

export const userLogout = async () => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.LOGOUT}`,
    config: { headers: { Authorization: `Bearer ${getCookie('refresh_token')}` } },
  });
};

export const verify2faRecoveryCode = async (otpDetails: {
  recovery_code: string;
  mfatoken: string;
}): Promise<LoginResponse> => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.VERIFY_2FA_RECOVERY_CODE}`,
    data: otpDetails,
  });
};
