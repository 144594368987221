import apiEndpoints from '@src/lib/apiEndpoints';
import { Staff } from '@src/Models/staff';

import apiRequest from './api';
import { GetTableFilterProps } from './type';

export const getStaffs = (params: GetTableFilterProps): Promise<Staff[]> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.STAFFS}`,
    config: { params },
  });
};

export const getStaffByID = async (id: string): Promise<Staff> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.STAFFS}/${id}`,
  });
};

export const getAllDepartments = async (params: { page: number }) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.DEPARTMENTS}`,
    config: { params },
  });
};

export const getDesignations = async (params: { page: number }) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.DESIGNATION}`,
    config: { params },
  });
};

export const getStaffCategories = async (params: { page: number }) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.STAFF_CATEGORY}`,
    config: { params },
  });
};
