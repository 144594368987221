import apiEndpoints from '@src/lib/apiEndpoints';
import { Student } from '@src/Models/student';

import apiRequest from './api';
import { GetTableFilterProps } from './type';

export const getStudent = (params: GetTableFilterProps): Promise<Student[]> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.STUDENTS}`,
    config: { params },
  });
};

export const getStudentById = async (id: string): Promise<Student> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.STUDENTS}/${id}`,
  });
};
