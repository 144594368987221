import { lazy } from 'react';

import { GeneralSubRoutes, RouteActions, Routes } from '@src/lib/constants';
import { RoutesConfigType } from '@src/lib/types';

const PaymentLinksCreateEdit = lazy(() => import('@src/screens/general/PaymentLinks/CreateLayout'));
const ManagePaymentLinks = lazy(() => import('@src/screens/general/PaymentLinks'));
const PaymentLinkDetailView = lazy(() => import('@src/screens/general/PaymentLinks/DetailsLayout/detailView'));

const generalRoutes: Array<RoutesConfigType> = [
  {
    path: Routes.GENERAL + GeneralSubRoutes.PAYMENT_LINKS,
    layout: 'private',
    component: ManagePaymentLinks,
  },
  {
    path: Routes.GENERAL + GeneralSubRoutes.PAYMENT_LINKS + '/:id',
    layout: 'private',
    component: PaymentLinkDetailView,
  },
  {
    path: Routes.GENERAL + GeneralSubRoutes.PAYMENT_LINKS + RouteActions.CREATE,
    layout: 'private',
    component: PaymentLinksCreateEdit,
  },
];

export default generalRoutes;
