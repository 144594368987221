import apiEndpoints from '@src/lib/apiEndpoints';
import { FormControllerDataReturn } from '@src/lib/types';
import { CategoryType } from '@src/Models/categoryType';

import apiRequest from './api';
import { GetTableFilterProps } from './type';

export const getCategoryType = (
  params: GetTableFilterProps,
  id?: string
): Promise<{ data: CategoryType[]; total: number }> => {
  const urlExtension = id ? `?category_id=${id}` : '';
  return apiRequest({
    method: 'GET',
    url: apiEndpoints.CATEGORY_TYPE + urlExtension,
    config: { params },
  });
};

export const createCategoryType = async (data: FormControllerDataReturn) => {
  return apiRequest({
    method: 'POST',
    url: apiEndpoints.CATEGORY_TYPE,
    data,
  });
};

export const updateCategoryType = async (id: string, data: FormControllerDataReturn) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.CATEGORY_TYPE}/${id}`,
    data,
  });
};

export const getCategoryTypeById = async (id: string): Promise<{ data: CategoryType }> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.CATEGORY_TYPE}/${id}`,
  });
};
