import apiEndpoints from '@src/lib/apiEndpoints';

import apiRequest from './api';

export const getRecoveryCode = async () => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.RECOVERY_CODE}`,
  });
};

export const generateCode = async () => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.GENERATE_SECRET}`,
  });
};

export const enable2FA = async (otp: string) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.ENABLE_2FA}`,
    data: { otp },
  });
};

export const disable2fa = async (password: string) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.DISABLE_2FA}`,
    data: { password },
  });
};
