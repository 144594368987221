import apiEndpoints from '@src/lib/apiEndpoints';
import { FormControllerDataReturn } from '@src/lib/types';
import { Activity } from '@src/Models/activity';
import { ActivityAllocationProps, UpdateSignupPaymentStatusProps } from '@src/screens/asa/signUp/type';

import apiRequest from './api';
import { GetTableFilterProps, SignUpDataProps } from './type';

export const getActivity = (params: GetTableFilterProps): Promise<{ data: Activity[]; total: number }> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.ACTIVITY}`,
    config: { params },
  });
};

export const getActivityByIdEnrolled = (
  id: string,
  params: GetTableFilterProps
): Promise<{ data: Activity[]; total: number }> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.ACTIVITY}/${id}`,
    config: { params },
  });
};

export const getActivityById = (id: string): Promise<{ data: Activity }> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.ACTIVITY}/${id}`,
  });
};

export const createActivity = async ({ data }: { data: FormControllerDataReturn }) => {
  return apiRequest({
    method: 'post',
    url: `${apiEndpoints.ACTIVITY}`,
    data: { ...data },
  });
};

export const updateActivity = async ({ data, id }: { id: string; data: FormControllerDataReturn }) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.ACTIVITY}/${id}`,
    data: { ...data },
  });
};

export const deleteActivity = async (id: string) => {
  return apiRequest({
    method: 'DELETE',
    url: `${apiEndpoints.ACTIVITY}/${id}`,
  });
};

export const activitySignUp = async (params: { eligible_grades: string; program_id: string; student_id: string }) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.ACTIVITY_SIGNUP}`,
    config: { params },
  });
};

export const processingSignUp = async (data: SignUpDataProps) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.PROCESSING_SIGNUP}`,
    data: { ...data },
  });
};

export const getSignUpList = async (params: GetTableFilterProps) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.PROCESSING_SIGNUP}`,
    config: { params },
  });
};

export const getSignUpById = async (id: string) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.PROCESSING_SIGNUP}/${id}`,
  });
};

export const updateSignUpPaymentStatus = async (id: string, data: UpdateSignupPaymentStatusProps) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.PROCESSING_SIGNUP}${apiEndpoints.UPDATE_STATUS}/${id}`,
    data,
  });
};

export const updateSignUpById = async (data: ActivityAllocationProps, id: string) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.PROCESSING_SIGNUP}/${id}`,
    data,
  });
};
