import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';

import Loader from './components/Loader';
import { SnackbarProvider } from './components/SnackbarProvider';
import { AuthContextProvider } from './contexts/AuthContextProvider';
import { GlobalUIContext, LoadingType, SnackType } from './contexts/GlobalUIContext';
import { GlobalStyles } from './lib/styles/GlobalStyles';
import { getMuiTheme } from './lib/styles/muiTheme';
import getTheme, { Modes, ModeType } from './lib/styles/theme';
import { Language, LanguageType } from './lib/types';
import App from './App';

const CLIENT_ID = process.env.REACT_APP_API_CLIENT_ID;

const AppWrapper = () => {
  const { i18n } = useTranslation();
  const [snackValue, setSnackValue] = useState<SnackType>();
  const [loading, setLoading] = useState<LoadingType>('modal');
  const [mode, setMode] = useState<Modes>(Modes.LIGHT);
  const theme = getTheme(mode);
  const muiTheme = getMuiTheme(mode);

  useEffect(() => {
    const initializeApplication = async () => {
      try {
        const storedMode = getStoredMode();
        setMode(storedMode);

        const storedLanguage = getStoredLanguage();
        await i18n.changeLanguage(storedLanguage);
      } catch (error) {
        console.error('Failed to initialize app settings:', error);
      } finally {
        setLoading(false);
      }
    };

    initializeApplication();
  }, [i18n]);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={muiTheme}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles />
          <GlobalUIContext.Provider value={{ mode, setMode, loading, setLoading, setSnackValue, snackValue }}>
            <AuthContextProvider>
              <GoogleOAuthProvider clientId={CLIENT_ID as string}>
                <App />
                {loading === 'modal' ? <Loader /> : null}
                {snackValue ? <SnackbarProvider /> : null}
              </GoogleOAuthProvider>
            </AuthContextProvider>
          </GlobalUIContext.Provider>
        </ThemeProvider>
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppWrapper;

const getStoredMode = (): ModeType => {
  const modeFromStorage = localStorage.getItem('mode') as ModeType;
  return modeFromStorage === Modes.DARK ? Modes.DARK : Modes.LIGHT;
};

const getStoredLanguage = (): LanguageType => {
  const langFromStorage = localStorage.getItem('lang') as LanguageType;
  return [Language.EN, Language.FR, Language.JA, Language.KO].includes(langFromStorage) ? langFromStorage : Language.EN;
};
