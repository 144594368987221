import { lazy } from 'react';

import { Routes } from '@src/lib/constants';
import { RoutesConfigType } from '@src/lib/types';

const Login = lazy(() => import('@src/screens/Login'));
const LoginWithOtp = lazy(() => import('@src/screens/LoginWithOtp'));
const LoginWithRecoveryCode = lazy(() => import('@src/screens/LoginWithOtp/recoveryCode'));

const loginRoutes: Array<RoutesConfigType> = [
  {
    path: Routes.LOGIN,
    component: Login,
    layout: 'restricted',
  },
  {
    path: Routes.LOGIN_OTP,
    component: LoginWithOtp,
    layout: 'restricted',
  },
  {
    path: Routes.LOGIN_RECOVERY_CODE,
    component: LoginWithRecoveryCode,
    layout: 'restricted',
  },
];

export default loginRoutes;
