import apiEndpoints from '@src/lib/apiEndpoints';
import { FormControllerDataReturn } from '@src/lib/types';
import { Contractor } from '@src/Models/contractor';

import apiRequest from './api';
import { GetTableFilterProps } from './type';

export const bulkImport = async (file: FormData) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.BULK_IMPORT}`,
    data: file,
    contentType: 'multipart/form-data',
  });
};

export const getContractors = (params: GetTableFilterProps): Promise<Contractor[]> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.CONTRACTORS}`,
    config: { params },
  });
};

export const getContractor = async (id: string): Promise<Contractor> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.CONTRACTORS}/${id}`,
  });
};

export const deleteContractor = async (id: string) => {
  return apiRequest({
    method: 'DELETE',
    url: `${apiEndpoints.CONTRACTORS}/${id}`,
  });
};

export const updateContractor = async ({ data, id }: { id: string; data: FormControllerDataReturn }) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.CONTRACTORS}/${id}`,
    data: { ...data },
  });
};
