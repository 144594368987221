import apiEndpoints from '@src/lib/apiEndpoints';

import apiRequest from './api';

export interface DownloadTransactionsProps {
  customer_ids?: string;
  status?: string;
}

export const downloadTransactions = async (params: DownloadTransactionsProps) => {
  return apiRequest({
    method: 'get',
    url: `${apiEndpoints.TRANSACTION}`,
    config: { params, responseType: 'blob' },
  });
};
