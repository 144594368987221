import { lazy } from 'react';

import { ApprovalRulesSubCatergory, PoMasterSubCategory, PoSubRoutes, RouteActions, Routes } from '@src/lib/constants';
import { RoutesConfigType } from '@src/lib/types';

const CreateEditCategory = lazy(() => import('@src/components/Category/CreateEditLayout'));
const PoTypeCreateEdit = lazy(() => import('@src/screens/PO/Master/CategoryType/CreateEditLayout'));
const PurchaseOrdersDetailView = lazy(() => import('@src/screens/PO/PuchaseOrders/detailView'));
const ReviewPurchaseOrdersDetailView = lazy(() => import('@src/screens/PO/ReviewPuchaseOrders/detailView'));
const CreateEditLayoutPO = lazy(() => import('@src/screens/PO/PuchaseOrders/CreateEditLayout'));
const PurchaseOrdersManagement = lazy(() => import('@src/screens/PO/PuchaseOrders'));
const ReviewPOList = lazy(() => import('@src/screens/PO/ReviewPuchaseOrders'));
const PoMaster = lazy(() => import('@src/screens/PO/Master'));
const CostCentresManagement = lazy(() => import('@src/screens/CostCentres'));
const CostCentresDetailView = lazy(() => import('@src/screens/CostCentres/DetailsLayout/detailView'));
const VendorsManagement = lazy(() => import('@src/screens/Vendors'));
const VendorsDetailView = lazy(() => import('@src/screens/Vendors/detailView'));
const OrganizationSettingsDetailView = lazy(() => import('@src/screens/OrganizationSettings/detailView'));
const OrganizationSettingsEditLayout = lazy(() => import('@src/screens/OrganizationSettings/EditLayout'));
const CreateEditVendor = lazy(() => import('@src/screens/Vendors/CreateEditLayout'));
const CreateEditCostCentresLayout = lazy(() => import('@src/screens/CostCentres/CreateEditLayout'));
const ApprovalRules = lazy(() => import('@src/screens/PO/ApprovalRules'));
const CreateEditApprovalRulesUsers = lazy(() => import('@src/screens/PO/ApprovalRules/CreateEditLayout'));

const poRoutes: Array<RoutesConfigType> = [
  {
    path: Routes.PO + PoSubRoutes.COST_CENTRES,
    layout: 'private',
    component: CostCentresManagement,
  },
  {
    path: Routes.PO + PoSubRoutes.COST_CENTRES + '/:id',
    layout: 'private',
    component: CostCentresDetailView,
  },

  {
    path: Routes.PO + PoSubRoutes.VENDORS,
    layout: 'private',
    component: VendorsManagement,
  },
  {
    path: Routes.PO + PoSubRoutes.VENDORS + '/:id',
    layout: 'private',
    component: VendorsDetailView,
  },
  {
    path: Routes.PO + PoSubRoutes.VENDORS + RouteActions.CREATE,
    layout: 'private',
    component: CreateEditVendor,
  },
  {
    path: Routes.PO + PoSubRoutes.VENDORS + RouteActions.EDIT + '/:id',
    layout: 'private',
    component: CreateEditVendor,
  },
  {
    path: Routes.PO + PoSubRoutes.PURCHASE_ORDERS,
    layout: 'private',
    component: PurchaseOrdersManagement,
  },
  {
    path: Routes.PO + PoSubRoutes.PURCHASE_ORDERS + '/:id',
    layout: 'private',
    component: PurchaseOrdersDetailView,
  },
  {
    path: Routes.PO + PoSubRoutes.PURCHASE_ORDERS + RouteActions.CREATE,
    layout: 'private',
    component: CreateEditLayoutPO,
  },
  {
    path: Routes.PO + PoSubRoutes.PURCHASE_ORDERS + RouteActions.EDIT + '/:id',
    layout: 'private',
    component: CreateEditLayoutPO,
  },
  {
    path: Routes.PO + PoSubRoutes.COST_CENTRES + RouteActions.CREATE,
    component: CreateEditCostCentresLayout,
    layout: 'private',
  },

  {
    path: Routes.PO + PoSubRoutes.MASTER + PoMasterSubCategory.CATEGORY + RouteActions.CREATE,
    component: CreateEditCategory,
    layout: 'private',
  },
  {
    path: Routes.PO + PoSubRoutes.MASTER + PoMasterSubCategory.CATEGORY + RouteActions.EDIT + '/:id',
    component: CreateEditCategory,
    layout: 'private',
  },
  {
    path: Routes.PO + PoSubRoutes.COST_CENTRES + RouteActions.EDIT + '/:id',
    component: CreateEditCostCentresLayout,
    layout: 'private',
  },

  {
    path: Routes.PO + PoSubRoutes.MASTER + PoMasterSubCategory.CATEGORY,
    component: PoMaster,
    layout: 'private',
  },
  {
    path: Routes.PO + PoSubRoutes.MASTER + PoMasterSubCategory.TYPE,
    component: PoMaster,
    layout: 'private',
  },
  {
    path: Routes.PO + PoSubRoutes.MASTER + PoMasterSubCategory.TYPE + RouteActions.CREATE,
    component: PoTypeCreateEdit,
    layout: 'private',
  },
  {
    path: Routes.PO + PoSubRoutes.MASTER + PoMasterSubCategory.TYPE + RouteActions.EDIT + '/:id',
    component: PoTypeCreateEdit,
    layout: 'private',
  },

  {
    path: Routes.PO + PoSubRoutes.ORGANIZATION_SETTINGS,
    component: OrganizationSettingsDetailView,
    layout: 'private',
  },
  {
    path: Routes.PO + PoSubRoutes.ORGANIZATION_SETTINGS + RouteActions.EDIT,
    component: OrganizationSettingsEditLayout,
    layout: 'private',
  },
  {
    path: Routes.PO + PoSubRoutes.APPROVAL_RULES + ApprovalRulesSubCatergory.USERS,
    layout: 'private',
    component: ApprovalRules,
  },
  {
    path: Routes.PO + PoSubRoutes.APPROVAL_RULES + ApprovalRulesSubCatergory.AMOUNT,
    layout: 'private',
    component: ApprovalRules,
  },
  {
    path: Routes.PO + PoSubRoutes.APPROVAL_RULES + ApprovalRulesSubCatergory.USERS + RouteActions.EDIT + '/:id',
    layout: 'private',
    component: CreateEditApprovalRulesUsers,
  },
  {
    path: Routes.PO + PoSubRoutes.APPROVAL_RULES + ApprovalRulesSubCatergory.AMOUNT + RouteActions.EDIT + '/:id',
    layout: 'private',
    component: CreateEditApprovalRulesUsers,
  },
  {
    path: Routes.PO + PoSubRoutes.APPROVAL_RULES + ApprovalRulesSubCatergory.USERS + RouteActions.CREATE,
    layout: 'private',
    component: CreateEditApprovalRulesUsers,
  },
  {
    path: Routes.PO + PoSubRoutes.APPROVAL_RULES + ApprovalRulesSubCatergory.AMOUNT + RouteActions.CREATE,
    layout: 'private',
    component: CreateEditApprovalRulesUsers,
  },
  {
    path: Routes.PO + PoSubRoutes.PURCHASE_ORDERS_REVIEW,
    layout: 'private',
    component: ReviewPOList,
  },
  {
    path: Routes.PO + PoSubRoutes.PURCHASE_ORDERS_REVIEW + '/:id',
    layout: 'private',
    component: ReviewPurchaseOrdersDetailView,
  },
];

export default poRoutes;
