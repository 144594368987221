import { lazy } from 'react';

import { LogsSubRoutes, Routes } from '@src/lib/constants';
import { RoutesConfigType } from '@src/lib/types';

const LogsSync = lazy(() => import('@src/screens/Logs/SyncUsers'));
const LogsApiRequests = lazy(() => import('@src/screens/Logs/ApiRequests'));
const LogsPaymentTransactions = lazy(() => import('@src/screens/Logs/PaymentTransactions'));

const logsRoute: Array<RoutesConfigType> = [
  {
    path: Routes.LOGS + LogsSubRoutes.SYNC_USERS,
    component: LogsSync,
    layout: 'private',
  },
  {
    path: Routes.LOGS + LogsSubRoutes.API_REQUESTS,
    component: LogsApiRequests,
    layout: 'private',
  },
  {
    path: Routes.LOGS + LogsSubRoutes.PAYEMENT_TRANSACTIONS,
    component: LogsPaymentTransactions,
    layout: 'private',
  },
];

export default logsRoute;
