import apiEndpoints from '@src/lib/apiEndpoints';
import { ApprovalRequest } from '@src/Models/ApprovalRequest';
import { PurchaseOrders } from '@src/Models/purchaseOrders';

import apiRequest from './api';
import { GetTableFilterProps } from './type';

export const createPurchaseOrder = (data: PurchaseOrders) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.PURCHASE_ORDER}`,
    data,
  });
};

export const editPurchaseOrder = (id: string, data: PurchaseOrders) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.PURCHASE_ORDER}/${id}`,
    data,
  });
};

export const editPurchaseOrderStatus = (id: string, data: ApprovalRequest) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.PURCHASE_ORDER_STATUS}/${id}`,
    data,
  });
};

export const getPurchaseOrderById = async (id: string): Promise<PurchaseOrders> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.PURCHASE_ORDER}/${id}`,
  });
};

export const getReviewPurchaseOrderById = async (id: string): Promise<PurchaseOrders> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.APPROVAL_REQUESTS}/${id}`,
  });
};

export const getPurchaseOrder = (params: GetTableFilterProps): Promise<PurchaseOrders[]> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.PURCHASE_ORDER}`,
    config: { params },
  });
};

export const getReviewPurchaseOrder = (params: GetTableFilterProps): Promise<PurchaseOrders[]> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.APPROVAL_REQUESTS}`,
    config: { params },
  });
};
