import { useNavigate } from 'react-router-dom';

import { BackButton, Description, Heading, MessageBox, Subheading, UnauthorizedContainer } from './style';

const UnauthorizedAccess = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <UnauthorizedContainer>
      <MessageBox>
        <Heading>Access Denied</Heading>
        <Subheading>You do not have permission to view this page.</Subheading>
        <Description>Please contact your administrator if you believe this is an error.</Description>
        <BackButton onClick={handleBack}>Go Back</BackButton>
      </MessageBox>
    </UnauthorizedContainer>
  );
};

export default UnauthorizedAccess;
