import apiEndpoints from '@src/lib/apiEndpoints';
import { RulesCreateType, RulesData } from '@src/Models/rulesUser';

import apiRequest from './api';
import { GetTableFilterProps } from './type';

export const getApprovalRulesUsers = (params: GetTableFilterProps): Promise<{ data: RulesData[]; total: number }> => {
  return apiRequest({
    method: 'GET',
    url: apiEndpoints.APPROVAL_RULES_USERS,
    config: { params },
  });
};

export const getApprovalRulesUsersById = async (id: string) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.APPROVAL_RULES_USERS}/${id}`,
  });
};

export const createApprovalRulesUsers = (data: RulesCreateType) => {
  return apiRequest({
    method: 'POST',
    url: apiEndpoints.APPROVAL_RULES_USERS,
    data: data,
  });
};

export const updateApprovalRulesUsers = async (id: string, data: RulesCreateType) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.APPROVAL_RULES_USERS}/${id}`,
    data: data,
  });
};

export const deleteApprovalRulesUsers = (id: string) => {
  return apiRequest({
    method: 'DELETE',
    url: `${apiEndpoints.APPROVAL_RULES_USERS}/${id}`,
  });
};

// Api for approval rules amount
export const getApprovalRulesAmount = (params: GetTableFilterProps): Promise<{ data: RulesData[]; total: number }> => {
  return apiRequest({
    method: 'GET',
    url: apiEndpoints.APPROVAL_RULES_AMOUNT,
    config: { params },
  });
};

export const updateApprovalRulesAmount = async (id: string, data: RulesCreateType) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.APPROVAL_RULES_AMOUNT}/${id}`,
    data: data,
  });
};

export const getApprovalRulesAmountById = async (id: string) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.APPROVAL_RULES_AMOUNT}/${id}`,
  });
};

export const createApprovalRulesAmount = (data: RulesCreateType) => {
  return apiRequest({
    method: 'POST',
    url: apiEndpoints.APPROVAL_RULES_AMOUNT,
    data: data,
  });
};

export const deleteApprovalRulesAmount = (id: string) => {
  return apiRequest({
    method: 'DELETE',
    url: `${apiEndpoints.APPROVAL_RULES_AMOUNT}/${id}`,
  });
};
