import { ComponentType } from 'react';
import * as yup from 'yup';

import { Field, SelectOptionType, ValueType } from '@src/components/FormController/type';

export type permissionEnum = 'VIEW_DATA_ONLY' | 'UPDATE_GATEWAY_CONFIG' | 'CREATE_REFUND';
export type routesConfigLayout = 'private' | 'public' | 'restricted';

export interface RoutesConfigType extends RouteComponentType {
  path: string;
  allowedPermission?: Array<permissionEnum>;
  layout: routesConfigLayout;
}

export interface RouteComponentType {
  component: ComponentType;
  isStyle?: boolean;
  isBreadcrumb?: boolean;
  hasMerchandiseSubHeader?: boolean;
}

export type LanguageType = Language.EN | Language.FR | Language.JA | Language.KO;
export enum Language {
  EN = 'en',
  KO = 'ko',
  FR = 'fr',
  JA = 'ja',
}

export enum DrawerItem {
  ASA = 'asa',
  PO = 'po',
  USERS = 'users',
  MERCHANDISE = 'merchandise',
  GATE = 'gate',
  CAFE = 'cafe',
  WALLET = 'wallet',
  GRADES = 'grades',
  REPORTS = 'reports',
  LOGS = 'logs',
  GENERAL = 'general',
}

export enum AsaHeader {
  HOME_ROOM = 'home-room',
}

export type Role = 'admin' | 'parent' | 'staff_cum_parent' | 'student' | 'super_admin';

export type FormControllerDataReturn = Record<string, ValueType>;

export type ActionType = 'add' | 'edit' | 'delete';

export interface GenericAccordionTableType {
  columnData: { id: string; label: string }[];
  addButtonLabel: string;
  title: string;
  fields: Field[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema?: yup.ObjectSchema<Record<string, any>>;
  onSubmit: (data: FormControllerDataReturn[]) => void;
}

export interface LocationType {
  name: string;
  iso2: string;
  id: string;
}

export interface FilterOptions {
  label: string;
  key: string;
  value: (string | SelectOptionType)[] | 'date';
}

export interface FilterSchema {
  filterOptions: FilterOptions[];
  handleScrollToBottom?: (selectedFilterModule: string) => void;
}

export type UploadFileType = (data: FormData) => Promise<void>;

export type StatusType =
  | 'PENDING_APPROVAL'
  | 'APPROVED'
  | 'REJECTED'
  | 'CONFIRMED'
  | 'PENDING'
  | 'PAID'
  | 'FAILED'
  | 'ENROLLED'
  | 'ACTIVE'
  | 'INACTIVE';

export interface ScheduleProps {
  start_date: string;
  end_date: string;
  student_id?: string;
  instructor_id?: string;
  program_id?: string;
  activity_id?: string;
}

export type GetRoutesConfigType = (
  data: {
    path: string;
    listing?: ComponentType;
    createEdit?: ComponentType;
    view?: ComponentType;
  }[]
) => RoutesConfigType[];
