import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Bedtime, LightMode } from '@mui/icons-material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { IconButton, Skeleton, useMediaQuery } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { googleLogout } from '@react-oauth/google';

import { AuthContext } from '@src/contexts/AuthContextProvider';
import { GlobalUIContext } from '@src/contexts/GlobalUIContext';
import { useAxiosApi } from '@src/hooks/useAxiosApi';
import { useGetTheme } from '@src/hooks/useGetTheme';
import { DrawerList, HeaderList, RouteActions, Routes } from '@src/lib/constants';
import { Modes } from '@src/lib/styles/theme';
import { DrawerItem, Language, LanguageType } from '@src/lib/types';
import { getCookie } from '@src/utils/getCookie';

import CustomMenu, { MenuOption } from '../MenuComponent';

import TemporaryDrawer from './Sidebar';
import { HeaderContainer, HeaderItem, HeaderLeft, HeaderRight, StyledLogo } from './styles';

const MainHeader = () => {
  const [open, setOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { mode, setMode } = useContext(GlobalUIContext);
  const { headers, userDetail, removeAuthToken, handleSetUserDetails } = useContext(AuthContext);
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const { handleSwitchUserRole } = useAxiosApi();
  const isHomeIcon = ['parent', 'student', 'staff_cum_parent'].includes(userDetail?.role || '');
  const { styledTheme } = useGetTheme();
  const isDarkMode = mode === Modes.DARK;
  const currentModule = location.pathname.split('/')[1] as DrawerItem;

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleSelectModule = (selectedModule: DrawerItem) => {
    const selectedHeaderList = HeaderList({ headers, selectedModule }) || ([] as string[]);
    navigate(`/${selectedModule}/${selectedHeaderList?.[0] || ''}`);
  };

  const toggleMode = (isCurrentlyDarkMode: boolean) => {
    const newMode = isCurrentlyDarkMode ? Modes.LIGHT : Modes.DARK;
    localStorage.setItem('mode', newMode);
    setMode?.(newMode);
  };

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang as LanguageType);
    localStorage.setItem('lang', lang);
  };

  const handleLogoClick = () => {
    if (isHomeIcon) navigate(Routes.DASHBOARD);
  };

  const settings: MenuOption[] = [
    ...(userDetail?.role === 'super_admin'
      ? [
          {
            key: '2fa-auth',
            label: `${userDetail?.['2fa'] ? 'Disable' : 'Enable'} 2FA`,
            onClick: () => {
              navigate(
                userDetail?.['2fa']
                  ? `${Routes.USERS}${Routes.TWO_FA}${RouteActions.DISABLE}`
                  : `${Routes.USERS}${Routes.TWO_FA}`
              );
            },
            icon: VpnKeyIcon,
          },
        ]
      : []),
    ...(userDetail?.ps_role === 'staff_cum_parent'
      ? [
          {
            label: `${t('switchTo')} ${userDetail?.role === 'parent' ? t('staff') : t('parent')}`,
            onClick: async () => {
              try {
                await handleSwitchUserRole({ data: { role: userDetail?.role === 'parent' ? 'staff' : 'parent' } });
                const tokenValue = getCookie('access_token');
                await handleSetUserDetails(tokenValue || '');
              } catch (err) {
                console.error(err);
              }
            },
            icon: SwitchAccountIcon,
            key: 'switch',
          },
        ]
      : []),
    {
      label: 'Logout',
      onClick: () => {
        googleLogout();
        removeAuthToken();
      },
      icon: ExitToAppIcon,
      key: 'logOut',
    },
  ];

  const LanguagesOptions: MenuOption[] = [
    { label: 'English', key: Language.EN, onClick: changeLanguage },
    { label: 'Français', key: Language.FR, onClick: changeLanguage },
    { label: '한국인', key: Language.KO, onClick: changeLanguage },
    { label: '日本語', key: Language.JA, onClick: changeLanguage },
  ];

  return (
    <HeaderContainer>
      <HeaderLeft>
        {isDesktop ? (
          <>
            <StyledLogo src='/logo.svg' alt='logo' onClick={handleLogoClick} />
            {isHomeIcon ? (
              <IconButton onClick={handleLogoClick}>
                <HomeIcon fontSize='large' />
              </IconButton>
            ) : null}
            {DrawerList.map(({ text }) => {
              if (!headers) return <Skeleton variant='rectangular' width={70} height={30} />;

              if (!(headers === 'super_admin' || headers[text])) return null;

              return (
                <HeaderItem key={text} $isActive={text === currentModule} onClick={() => handleSelectModule(text)}>
                  {t(text)}
                </HeaderItem>
              );
            })}
          </>
        ) : (
          <>
            <IconButton onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            {isHomeIcon ? (
              <IconButton onClick={handleLogoClick}>
                <HomeIcon />
              </IconButton>
            ) : null}
          </>
        )}
        <TemporaryDrawer isOpen={open} toggleDrawer={toggleDrawer} />
      </HeaderLeft>
      <HeaderRight>
        <CustomMenu options={LanguagesOptions} menuID='translation-menu'>
          <img src='/icons/translation-icon.svg' alt='ss' />
        </CustomMenu>
        <IconButton
          sx={{ ...(isDarkMode && { color: '#FDB813' }), display: 'flex' }}
          onClick={() => toggleMode(isDarkMode)}
        >
          {isDarkMode ? <LightMode /> : <Bedtime style={{ color: styledTheme.colors.black }} />}
        </IconButton>
        <CustomMenu options={settings} menuID='profile-menu'>
          <Avatar
            alt={userDetail?.first_name?.toUpperCase() || 'A'}
            src='/static/images/avatar/2.jpg'
            style={{ background: styledTheme.colors.black, color: styledTheme.colors.white }}
          />
        </CustomMenu>
      </HeaderRight>
    </HeaderContainer>
  );
};

export default MainHeader;
