import { lazy } from 'react';

import { RouteActions, Routes, UsersSubRoutes } from '@src/lib/constants';
import { RoutesConfigType } from '@src/lib/types';

const StaffDetailView = lazy(() => import('@src/screens/Staff/detailView'));
const ParentDetailView = lazy(() => import('@src/screens/Parent/detailView'));
const StudentManagement = lazy(() => import('@src/screens/ManageStudent'));
const StudentDetailView = lazy(() => import('@src/screens/ManageStudent/detailView'));
const TwoFactorAuth = lazy(() => import('@src/screens/2FA'));
const TwoFactorEnable = lazy(() => import('@src/screens/2FA/TwoFAEnable'));
const TwoFADisable = lazy(() => import('@src/screens/2FA/TwoFADisable'));
const StaffManagement = lazy(() => import('@src/screens/Staff'));
const ParentManagement = lazy(() => import('@src/screens/Parent'));
const ManageContractorDetails = lazy(() => import('@src/screens/ManageContractor/detailView'));
const ManageContractorEdit = lazy(() => import('@src/screens/ManageContractor/edit'));
const ManageAdmin = lazy(() => import('@src/screens/ManageAdmin'));
const UserDetailView = lazy(() => import('@src/screens/ManageAdmin/detailView'));
const ManageUserEdit = lazy(() => import('@src/screens/ManageAdmin/edit'));
const ManageContractor = lazy(() => import('@src/screens/ManageContractor'));
const AllUsersManagement = lazy(() => import('@src/screens/ManageUsers'));
const AllUsersDetailView = lazy(() => import('@src/screens/ManageUsers/detailView'));
const UserEditManagement = lazy(() => import('@src/screens/ManageUsers/userEdit'));
const Roles = lazy(() => import('@src/screens/Roles'));
const RoleDetailView = lazy(() => import('@src/screens/Roles/detailView'));

const usersRoutes: Array<RoutesConfigType> = [
  {
    path: Routes.USERS + UsersSubRoutes.MANAGE_CONTRACTOR,
    component: ManageContractor,
    layout: 'private',
  },
  {
    path: Routes.USERS + UsersSubRoutes.MANAGE_CONTRACTOR + '/:id',
    layout: 'private',
    component: ManageContractorDetails,
  },
  {
    path: Routes.USERS + UsersSubRoutes.MANAGE_CONTRACTOR + RouteActions.EDIT + '/:id',
    layout: 'private',
    component: ManageContractorEdit,
  },
  {
    path: Routes.USERS + UsersSubRoutes.MANAGE_STAFF,
    layout: 'private',
    component: StaffManagement,
  },
  {
    path: Routes.USERS + UsersSubRoutes.MANAGE_STAFF_BY_ID,
    layout: 'private',
    component: StaffDetailView,
  },
  {
    path: Routes.USERS + UsersSubRoutes.MANAGE_PARENT,
    layout: 'private',
    component: ParentManagement,
  },
  {
    path: Routes.USERS + UsersSubRoutes.MANAGE_PARENT + '/:id',
    layout: 'private',
    component: ParentDetailView,
  },
  {
    path: Routes.USERS + UsersSubRoutes.MANAGE_STUDENT,
    layout: 'private',
    component: StudentManagement,
  },
  {
    path: Routes.USERS + UsersSubRoutes.MANAGE_ADMIN,
    layout: 'private',
    component: ManageAdmin,
  },
  {
    path: Routes.USERS + UsersSubRoutes.MANAGE_STUDENT + '/:id',
    layout: 'private',
    component: StudentDetailView,
  },
  {
    path: Routes.USERS + UsersSubRoutes.MANAGE_ALL_USERS,
    layout: 'private',
    component: AllUsersManagement,
  },
  {
    path: Routes.USERS + UsersSubRoutes.MANAGE_ALL_USERS + '/:id',
    layout: 'private',
    component: AllUsersDetailView,
  },
  {
    path: Routes.USERS + UsersSubRoutes.MANAGE_ALL_USERS + RouteActions.EDIT + '/:id',
    layout: 'private',
    component: UserEditManagement,
  },
  {
    path: Routes.USERS + UsersSubRoutes.MANAGE_ADMIN + '/:id',
    layout: 'private',
    component: UserDetailView,
  },
  {
    path: Routes.USERS + UsersSubRoutes.MANAGE_ADMIN + RouteActions.EDIT + '/:id',
    layout: 'private',
    component: ManageUserEdit,
  },
  {
    path: Routes.USERS + Routes.TWO_FA,
    component: TwoFactorAuth,
    layout: 'private',
    isStyle: false,
  },
  {
    path: Routes.USERS + Routes.TWO_FA + RouteActions.ENABLE,
    component: TwoFactorEnable,
    layout: 'private',
    isStyle: false,
  },
  {
    path: Routes.USERS + Routes.TWO_FA + RouteActions.DISABLE,
    component: TwoFADisable,
    layout: 'private',
    isStyle: false,
  },
  {
    path: Routes.USERS + UsersSubRoutes.ROLES,
    component: Roles,
    layout: 'private',
  },
  {
    path: Routes.USERS + UsersSubRoutes.ROLES + '/:id',
    component: RoleDetailView,
    layout: 'private',
  },
];

export default usersRoutes;
