import { lazy } from 'react';

import { ReportsSubRoutes, Routes } from '@src/lib/constants';
import { RoutesConfigType } from '@src/lib/types';

const AttendanceSheetReports = lazy(() => import('@src/screens/asa/Reports/AttendanceSheet'));
const ActivityEnrollmentsReports = lazy(() => import('@src/screens/asa/Reports/ActivityEnrollments'));
const PaymentTransactionsReports = lazy(() => import('@src/screens/asa/Reports/PaymentTransactions'));
const WeeklyScheduleReports = lazy(() => import('@src/screens/asa/Reports/WeeklySchedule'));
const DaywiseProgramScheduleReports = lazy(() => import('@src/screens/asa/Reports/DaywiseProgramSchedule'));
const WaitingActivityListReports = lazy(() => import('@src/screens/asa/Reports/WaitingActivityList'));
const PromgramSubmissionReports = lazy(() => import('@src/screens/asa/Reports/ProgramSubmission'));

const reportsRoutes: Array<RoutesConfigType> = [
  {
    path: Routes.REPORTS + ReportsSubRoutes.ATTENDANCE_SHEET,
    layout: 'private',
    component: AttendanceSheetReports,
  },
  {
    path: Routes.REPORTS + ReportsSubRoutes.PAYMENT_TRANSACTIONS,
    layout: 'private',
    component: PaymentTransactionsReports,
  },
  {
    path: Routes.REPORTS + ReportsSubRoutes.ACTIVITY_ENROLLMENTS,
    layout: 'private',
    component: ActivityEnrollmentsReports,
  },
  {
    path: Routes.REPORTS + ReportsSubRoutes.WEEKLY_SCHEDULE,
    layout: 'private',
    component: WeeklyScheduleReports,
  },
  {
    path: Routes.REPORTS + ReportsSubRoutes.DAYWISE_PROGRAM_SCHEDULE,
    layout: 'private',
    component: DaywiseProgramScheduleReports,
  },
  {
    path: Routes.REPORTS + ReportsSubRoutes.WAITING_LIST,
    layout: 'private',
    component: WaitingActivityListReports,
  },
  {
    path: Routes.REPORTS + ReportsSubRoutes.PROGRAM_SUBMISSIONS,
    layout: 'private',
    component: PromgramSubmissionReports,
  },
];

export default reportsRoutes;
