import { CategoryModulesType } from '@src/components/Category';
import apiEndpoints from '@src/lib/apiEndpoints';
import { FormControllerDataReturn } from '@src/lib/types';
import { Category } from '@src/Models/category';
import { ResponseData } from '@src/screens/Staff/type';

import apiRequest from './api';
import { GetTableFilterProps } from './type';

export const createCategory = async (data: FormControllerDataReturn, moduleType: CategoryModulesType) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.CATEGORY}`,
    data: { ...data, module_type: moduleType },
  });
};

export const updateCategory = async (id: string, data: FormControllerDataReturn, moduleType: CategoryModulesType) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.CATEGORY}/${id}`,
    data: { ...data, module_type: moduleType },
  });
};

export const getCategory = (
  params: GetTableFilterProps,
  moduleType: CategoryModulesType
): Promise<ResponseData<Category>> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.CATEGORY}`,
    config: { params: { ...params, module_type: moduleType } },
  });
};

export const getCategoryById = async (id: string) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.CATEGORY}/${id}`,
  });
};

export const deleteCategory = async (id: string) => {
  return apiRequest({
    method: 'DELETE',
    url: `${apiEndpoints.CATEGORY}/${id}`,
  });
};
