import apiEndpoints from '@src/lib/apiEndpoints';
import { CreateEditVendorProps } from '@src/Models/returnProps';
import { Vendor } from '@src/Models/vendor';

import apiRequest from './api';
import { GetTableFilterProps } from './type';

export const getVendors = (params: GetTableFilterProps): Promise<Vendor[]> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.VENDORS}`,
    config: { params },
  });
};

export const getVendorsById = async (id: string): Promise<Vendor> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.VENDORS}/${id}`,
  });
};

export const createVendor = (data: CreateEditVendorProps) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.VENDORS}`,
    data,
  });
};

export const editVendor = (id: string, data: CreateEditVendorProps) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.VENDORS}/${id}`,
    data,
  });
};
