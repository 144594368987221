import styled from 'styled-components';

import { styled as MuiStyled } from '@mui/material';

export const StyledLoadingText = styled.h3`
  font-weight: 500;
  text-align: center;
  margin-top: 25px;
`;

export const StyledLoaderContainer = MuiStyled('div')(({ theme }) => ({
  padding: theme.spacing(7),
}));
