import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

import MainLayout from '@src/components/MainLayout';
import { useAuthPermission } from '@src/hooks/useAuthPermission';
import { Routes } from '@src/lib/constants';
import { isAuthenticated } from '@src/lib/helper';
import { RouteComponentType } from '@src/lib/types';
import UnauthorizedAccess from '@src/screens/UnauthorizedAccess';

const PrivateRoute = ({
  component: Component,
  isStyle = true,
  isBreadcrumb = true,
  hasMerchandiseSubHeader,
}: RouteComponentType) => {
  const location = useLocation();
  const permission = useAuthPermission();
  const isRedirect = useSearchParams()[0]?.get('redirect') === 'true';

  if (!isAuthenticated()) {
    if (isRedirect) {
      localStorage.setItem('redirectionRoute', location.pathname);
    }

    return <Navigate to={Routes.LOGIN} replace />;
  }

  if (!permission) return <UnauthorizedAccess />;

  return isStyle ? (
    <MainLayout isBreadcrumb={isBreadcrumb} hasMerchandiseSubHeader={hasMerchandiseSubHeader}>
      <Component />
    </MainLayout>
  ) : (
    <Component />
  );
};

export default PrivateRoute;
