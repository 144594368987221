/**
 * This represents the name of the cookie to be set.
 * @param name
 * This represents the value to be assigned to the cookie.
 * @param value
 * This represents the number of days the cookie should live.
 * @param daysToLive
 */
export function setCookie(name: string, value: string, daysToLive: number) {
  let cookie = name + '=' + encodeURIComponent(value);
  const currentTime = Math.floor(Date.now() / 1000);

  if (daysToLive !== undefined) {
    cookie += '; max-age=' + (daysToLive / 1000 - currentTime);
  }

  document.cookie = cookie + ';path=/';
}
