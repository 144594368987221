const apiEndpoints = {
  USER_DETAILS: '/userData',
  SIGN_UP_GOOGLE: '/auth/user-login',
  ADMIN_LOGIN: '/auth/admin-login',
  LOGOUT: '/auth/logout',
  VERIFY_2FA: '/auth/verify-2fa',
  VERIFY_2FA_RECOVERY_CODE: '/auth/recovery-code',
  REFRESH_TOKEN: 'auth/refresh-token',
  BULK_IMPORT: 'contractor/bulk-store',
  PHOTO_UPLOAD: '/sync-images',
  CONTRACTORS: 'contractors',
  SELF: '/self',
  STAFFS: '/staffs',
  USERS: '/users',
  ROLES: '/roles',
  MODULES: '/modules',
  PROGRAMS: '/programs',
  PRODUCTS: '/merchandise/product',
  CART: '/merchandise/cart',
  MAPPING: '/activity-list',
  CREATE_MAPPING: '/activity-mapping',
  PROGRAM_DELETE: '/programs/status',
  PRODUCT_DELETE: '/products/status',
  PROGRAM_PAYMENT: '/payment/checkout',
  PARENTS: '/parents',
  DEPARTMENTS: '/department',
  COST_CENTRES: '/cost-centre',
  STUDENTS: '/students',
  ENABLE_2FA: '2fa/enable-2fa',
  DISABLE_2FA: '2fa/disable-2fa',
  RECOVERY_CODE: '2fa/recovery-code',
  GENERATE_SECRET: '2fa/generate-secret',
  VENDORS: '/vendors',
  ORGANIZATION_SETTINGS: 'organization-settings',
  ORGANIZATION_SETTINGS_UPDATE: 'organization-settings/update',
  ACTIVITY: '/activity',
  ACTIVITY_SIGNUP: '/activity-signup',
  PROCESSING_SIGNUP: '/processing-signup',
  UPDATE_STATUS: '/update-status',
  CATEGORY: '/category',
  MERCHANDISE_CATEGORY: '/merchandise/category',
  MERCHANDISE_PRODUCT_DELETE_VARIANT: '/merchandise/product/delete-variant',
  MERCHANDISE_ATTRIBUTES: '/merchandise/attribute',
  DESIGNATION: '/designation',
  STAFF_CATEGORY: '/staff-category',
  PURCHASE_ORDER: '/purchase-order',
  APPROVAL_REQUESTS: '/po-requests',
  PURCHASE_ORDER_STATUS: '/purchase-order/update-status',
  UPLOAD_FILES: '/generate-s3-url',
  ADDITIONAL_PERMISSIONS: '/additional-permissions',
  SYNC_USERS: '/logs/sync-users',
  PAYMENT_TRANSACTIONS: '/payment/transactions',
  API_REQUESTS: '/logs/api-requests',
  SESSION: '/session',
  SEASON: '/season',
  SESSION_MAPPING: '/session-mapping',
  SESSION_LIST: '/session-list',
  SCHEDULE: '/schedule',
  SCHEDULE_LIST: '/schedule-list',
  CATEGORY_TYPE: '/category-type',
  BUDGET_CODE: '/budget-code',
  APPROVAL_RULES_USERS: '/po-rules/user',
  APPROVAL_RULES_AMOUNT: '/po-rules/amount',
  PAYMENT_STATUS: '/payment/status',
  POWER_SCHOOL: '/powerschool',
  RESET_PO_SERIAL: '/organization-settings/reset-po-serial',
  PAYMENT_LINK: '/payment/payment-links',
  CREATE_PAYMENT_LINK: '/payment/create-checkout',
  DOWNLOAD_SHEET: 'report/attendance',
  TRANSACTION: 'report/transaction',
  WEEKLY_SCHEDULE: 'report/weekly-schedule',
  DAYWISE_PROGRAM_SCHEDULE: 'report/day-schedule',
  PROGRAM_SUBMISSION: 'report/program-submission',
  OFF_DAYS: '/off-days',
  WAITING_LIST: '/waiting-list',
  MANUALLY_ASSIGN: '/manually-assign',
  WAITING_ACTIVITY_LIST: 'report/waiting-list-sheet',
  SYNC_GOOGLE_SHEET: '/sync-google-sheet',
  TAGS: '/tags',
  ADD_ATTRIBUTE_VALUE: '/add-value',
  UPDATE_ATTRIBUTE_VALUE: '/update-value',
  REMOVE_ATTRIBUTE_VALUE: '/remove-value',
  MERCHANDISE_PRODUCT: '/merchandise/product',
  MERCHANDISE_UPDATE_VARIANT: '/merchandise/product/update-variant',
  MERCHANDISE_ADD_VARIANT: '/merchandise/product/add-variant',
  SWITCH_USER: 'users/switch-user',
};

export default apiEndpoints;
