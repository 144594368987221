import apiEndpoints from '@src/lib/apiEndpoints';
import { BudgetCode } from '@src/Models/budgetCode';

import apiRequest from './api';

export const getBudgetCode = (id?: string): Promise<{ data: BudgetCode[]; total: number }> => {
  const urlExtension = id ? `?cost_centre_id=${id}` : '';
  return apiRequest({
    method: 'GET',
    url: apiEndpoints.BUDGET_CODE + urlExtension,
  });
};
