import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { MapSubHeaderContainer, RouteMapItem } from './styles';

const MapSubHeader = () => {
  const location = useLocation();
  const [locationMap, setLocationMap] = useState<string[]>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const pathParts = location.pathname.split('/').filter(Boolean);

    if (location.pathname.includes('edit')) {
      pathParts.pop();
    }

    setLocationMap(pathParts);
  }, [location.pathname]);

  const handleBreadcrumbClick = (index: number) => {
    if (index === 0) {
      const secondBreadcrumbPath = `/${locationMap.slice(0, 2).join('/')}`;

      if (locationMap.length > 1) {
        navigate(secondBreadcrumbPath);
      }
    } else if (index > 0 && index < locationMap.length - 1) {
      const pathToNavigate = `/${locationMap.slice(0, index + 1).join('/')}`;
      navigate(pathToNavigate);
    }
  };

  return (
    <MapSubHeaderContainer>
      {locationMap.map((pathName, index) => (
        <RouteMapItem
          key={`${pathName}-${index}`}
          onClick={() => handleBreadcrumbClick(index)}
          style={{ cursor: 'pointer' }}
        >
          {t(pathName)}
          {index !== locationMap.length - 1 && <img src='/icons/ForwardArrowIcon.svg' alt='Forward-Arrow-Icon' />}
        </RouteMapItem>
      ))}
    </MapSubHeaderContainer>
  );
};

export default MapSubHeader;
