import apiEndpoints from '@src/lib/apiEndpoints';
import { FormControllerDataReturn } from '@src/lib/types';
import { Season } from '@src/Models/season';
import { Session, SessionMappingData, SessionMappingResponse } from '@src/Models/session';

import apiRequest from './api';
import { GetTableFilterProps } from './type';

export const getSession = (params: GetTableFilterProps): Promise<{ data: Session[]; total: number }> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.SESSION}`,
    config: { params },
  });
};

export const createSession = async (data: FormControllerDataReturn) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.SESSION}`,
    data: data,
  });
};

export const updateSession = async (id: string, data: FormControllerDataReturn) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.SESSION}/${id}`,
    data: data,
  });
};

export const getSessionById = async (id: string) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.SESSION}/${id}`,
  });
};

export const deleteSession = async (id: string) => {
  return apiRequest({
    method: 'DELETE',
    url: `${apiEndpoints.SESSION}/${id}`,
  });
};

export const getSeason = (params: GetTableFilterProps): Promise<{ data: Season[]; total: number }> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.SEASON}`,
    config: { params },
  });
};

export const createSeason = async (data: FormControllerDataReturn) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.SEASON}`,
    data: data,
  });
};

export const updateSeason = async (id: string, data: FormControllerDataReturn) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.SEASON}/${id}`,
    data: data,
  });
};

export const getSeasonById = async (id: string) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.SEASON}/${id}`,
  });
};

export const deleteSeason = async (id: string) => {
  return apiRequest({
    method: 'DELETE',
    url: `${apiEndpoints.SEASON}/${id}`,
  });
};

export const getSessionMapping = async (
  params: GetTableFilterProps
): Promise<{ data: SessionMappingResponse[]; total: number }> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.SESSION}?mapping=true`,
    config: { params },
  });
};

export const createSessionMapping = async (data: SessionMappingData) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.SESSION_MAPPING}`,
    data: data,
  });
};

export const updateSessionMapping = async (id: string, data: SessionMappingData) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.SESSION_MAPPING}/${id}`,
    data: data,
  });
};

export const deleteSessionMapping = async (id: string) => {
  return apiRequest({
    method: 'DELETE',
    url: `${apiEndpoints.SESSION_LIST}/${id}`,
  });
};

export const getSessionMappingById = async (id: string) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.SESSION}?mapping=true&id=${id}`,
  });
};
