import { useContext } from 'react';
import { useLocation } from 'react-router';

import { AuthContext } from '@src/contexts/AuthContextProvider';
import { Routes } from '@src/lib/constants';
import { AsaHeader, DrawerItem } from '@src/lib/types';
import { PermissionsType } from '@src/Models/modules';

export const useAuthPermission: () => PermissionsType | boolean | 'loading' = () => {
  const location = useLocation();
  const { headers: permittedHeaders, userDetail } = useContext(AuthContext);

  if (!permittedHeaders) return 'loading';

  if (permittedHeaders === 'super_admin') return true;

  const selectedModule = location.pathname.split('/')[1] as DrawerItem;
  const selectedHeader = location.pathname.split('/')[2] as string;

  if (
    ['parent', 'student', 'staff_cum_parent'].includes(userDetail?.role || '') &&
    Routes.DASHBOARD.includes(selectedModule)
  )
    return 'read';

  if (userDetail?.staff_id?.home_room && selectedModule === DrawerItem.ASA && selectedHeader === AsaHeader.HOME_ROOM)
    return 'read';

  const permission = permittedHeaders?.[selectedModule]?.[selectedHeader] as PermissionsType | undefined;

  if (!permission) return false;

  const permissions: PermissionsType[] = ['read', 'write', 'delete'];

  if (!permissions.includes(permission)) return false;

  const isEditRoute = location.pathname.split('/').includes('edit');

  if (permission === 'read' && isEditRoute) return false;

  return permission;
};
