import { useContext } from 'react';

import { GlobalUIContext } from '@src/contexts/GlobalUIContext';
import getTheme from '@src/lib/styles/theme';

export const useGetTheme = () => {
  const { mode } = useContext(GlobalUIContext);
  const styledTheme = getTheme(mode);
  return {
    styledTheme,
  };
};
