import apiEndpoints from '@src/lib/apiEndpoints';
import { Logs } from '@src/Models/logs';

import apiRequest from './api';
import { GetTableFilterProps } from './type';

export const getSyncLogs = async (params: GetTableFilterProps): Promise<{ data: Logs[] }> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.SYNC_USERS}`,
    config: { params },
  });
};

export const getApiRequestsLogs = async (params: GetTableFilterProps): Promise<{ data: Logs[] }> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.API_REQUESTS}`,
    config: { params },
  });
};

export const getPaymentTransactionsLogs = async (params: GetTableFilterProps): Promise<{ data: Logs[] }> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.PAYMENT_TRANSACTIONS}`,
    config: { params },
  });
};
