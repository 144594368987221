import apiEndpoints from '@src/lib/apiEndpoints';

import apiRequest from './api';

export const getDepartments = (id?: string) => {
  const urlExtension = id ? `?cost_centre_id=${id}` : '';
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.DEPARTMENTS}${urlExtension}`,
  });
};
