import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import { KeyboardArrowDown } from '@mui/icons-material';

import { useAxiosApi } from '@src/hooks/useAxiosApi';
import { initialTableFilterProps } from '@src/lib/constants';
import { DrawerItem } from '@src/lib/types';
import { ProductCategory } from '@src/Models/ProductCategory';

import {
  AllSubHeadersContainer,
  DropdownContainer,
  DropdownSubHeader,
  MerchandiseSubHeader,
  MerchandiseSubHeaderContainer,
  StyledCloseIcon,
  StyledIconButton,
} from './styles';

const MerchandiseSubHeaderLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleGetProductCategories } = useAxiosApi();

  const [open, setOpen] = useState<boolean>(false);
  const [productCategoriesData, setProductCategoriesData] = useState<ProductCategory[]>();

  const currentModule = location.pathname.split('/')[1] as DrawerItem;

  const handleSubHeaderClick = (subHeader: string) => {
    navigate(`/${currentModule}/${subHeader}`);
  };

  const fetchProductDetaildata = async () => {
    try {
      const res = await handleGetProductCategories({ data: initialTableFilterProps });
      setProductCategoriesData(res?.data);
    } catch (err) {
      console.error('Error: ', err);
    }
  };

  useEffect(() => {
    fetchProductDetaildata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <MerchandiseSubHeaderContainer>
        <MerchandiseSubHeader onClick={() => setOpen(prev => !prev)}>
          {t('all')} {t('categories')}
          <KeyboardArrowDown />
        </MerchandiseSubHeader>
        {productCategoriesData?.map(({ id, name }) => (
          <MerchandiseSubHeader key={id} onClick={() => handleSubHeaderClick(id)}>
            {t(name)}
          </MerchandiseSubHeader>
        ))}
      </MerchandiseSubHeaderContainer>
      {open ? (
        <DropdownContainer>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h3>{t('categories')}</h3>
            <StyledIconButton onClick={() => setOpen(false)}>
              <StyledCloseIcon />
            </StyledIconButton>
          </div>
          <AllSubHeadersContainer>
            {productCategoriesData?.map(({ id, name }, index) => (
              <DropdownSubHeader key={id} $index={index + 1} onClick={() => handleSubHeaderClick(id)}>
                {t(name)}
              </DropdownSubHeader>
            ))}
          </AllSubHeadersContainer>
        </DropdownContainer>
      ) : null}
    </div>
  );
};

export default MerchandiseSubHeaderLayout;
