import apiEndpoints from '@src/lib/apiEndpoints';

import apiRequest from './api';

export interface DownloadSheetProps {
  program_id: string;
  activity_id: string;
  instructor_id: string;
}

export const downloadSheet = async (params: DownloadSheetProps) => {
  return apiRequest({
    method: 'get',
    url: `${apiEndpoints.DOWNLOAD_SHEET}`,
    config: { params, responseType: 'blob' },
  });
};
