import apiEndpoints from '@src/lib/apiEndpoints';
import { PaymentLink } from '@src/Models/paymentLink';
import { PaymentLinkReturnProps } from '@src/Models/returnProps';
import { ResponseData } from '@src/screens/Staff/type';

import apiRequest from './api';
import { GetTableFilterProps } from './type';

export const getPaymentStatus = async (id: string) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.PAYMENT_STATUS}/${id}`,
  });
};

export const getPaymentLinkById = async (id: string): Promise<{ data: PaymentLinkReturnProps }> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.PAYMENT_LINK}/${id}`,
  });
};

export const createPaymentLink = (data: PaymentLink) => {
  return apiRequest({
    method: 'POST',
    url: apiEndpoints.CREATE_PAYMENT_LINK,
    data: data,
  });
};

export const getPaymentLinks = (params: GetTableFilterProps): Promise<ResponseData<PaymentLinkReturnProps>> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.PAYMENT_LINK}`,
    config: { params },
  });
};
