import { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import Loader from '@src/components/Loader';
import { AuthContext } from '@src/contexts/AuthContextProvider';
import { Routes } from '@src/lib/constants';
import { fetchAccessToken, getPermittedRoute, isAuthenticated } from '@src/lib/helper';
import { getCookie } from '@src/utils/getCookie';

export const RootRestrictedRoute = () => {
  const { headers, accessToken } = useContext(AuthContext);
  const [authPassed, setAuthPassed] = useState<boolean | null>(null);

  useEffect(() => {
    if (!isAuthenticated()) {
      if (!getCookie('access_token') && getCookie('refresh_token')) {
        fetchAccessToken().then(data => setAuthPassed(data));
      } else {
        setAuthPassed(false);
      }
    } else {
      setAuthPassed(true);
    }
  }, [accessToken]);

  if (typeof authPassed === 'boolean') {
    if (!authPassed) {
      return <Navigate to={Routes.LOGIN} replace />;
    }

    return <Navigate to={getPermittedRoute(headers)} replace />;
  }

  return <Loader />;
};
