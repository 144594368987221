import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Skeleton } from '@mui/material';

import { AuthContext } from '@src/contexts/AuthContextProvider';
import { HeaderList } from '@src/lib/constants';
import { DrawerItem } from '@src/lib/types';

import { SubHeader, SubHeaderContainer } from './styles';

const SubHeaders = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { headers } = useContext(AuthContext);

  const currentModule = location.pathname.split('/')[1] as DrawerItem;
  const currentSubHeader = location.pathname.split('/')[2] as string;

  const subHeaders = HeaderList({ headers, selectedModule: currentModule });

  const handleSubHeaderClick = (subHeader: string) => {
    navigate(`/${currentModule}/${subHeader}`);
  };

  return (
    <SubHeaderContainer>
      {subHeaders
        ? subHeaders.map(subHeader => (
            <SubHeader
              key={subHeader}
              $isActive={currentSubHeader === subHeader}
              onClick={() => handleSubHeaderClick(subHeader)}
            >
              {t(subHeader)}
            </SubHeader>
          ))
        : Array(8)
            .fill('')
            .map(() => <Skeleton variant='rectangular' width={70} height={30} />)}
    </SubHeaderContainer>
  );
};

export default SubHeaders;
