import apiEndpoints from '@src/lib/apiEndpoints';
import { createEditCostCentreProps } from '@src/Models/returnProps';

import apiRequest from './api';
import { GetTableFilterProps } from './type';

export const getCostCentres = (params: GetTableFilterProps) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.COST_CENTRES}`,
    config: { params },
  });
};

export const createCostCentres = (data: createEditCostCentreProps) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.COST_CENTRES}`,
    data,
  });
};

export const editCostCentres = (id: string, data: createEditCostCentreProps) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.COST_CENTRES}/${id}`,
    data,
  });
};

export const getCostCentre = (id: string) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.COST_CENTRES}/${id}`,
  });
};

export const deleteCostCentre = (id: string) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.COST_CENTRES}${apiEndpoints.UPDATE_STATUS}/${id}`,
  });
};
