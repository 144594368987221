import { PropsWithChildren } from 'react';

import { useMediaQuery } from '@mui/material';

import MainHeader from './MainHeader';
import MapSubHeader from './MapSubHeader';
import MerchandiseSubHeader from './MerchandiseSubHeader';
import { StyleChildContainer, StyledMainContainer } from './styles';
import SubHeaders from './SubHeaders';

const MainLayout = ({
  children,
  isBreadcrumb,
  hasMerchandiseSubHeader,
}: { isBreadcrumb?: boolean; hasMerchandiseSubHeader?: boolean } & PropsWithChildren) => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  return (
    <StyledMainContainer>
      <MainHeader />
      {isBreadcrumb ? (
        <>
          {isDesktop ? <SubHeaders /> : null}
          <MapSubHeader />
        </>
      ) : null}
      {hasMerchandiseSubHeader ? <MerchandiseSubHeader /> : null}
      <StyleChildContainer $isBreadcrumbOff={!isBreadcrumb}>{children}</StyleChildContainer>
    </StyledMainContainer>
  );
};

export default MainLayout;
