import apiEndpoints from '@src/lib/apiEndpoints';
import { Modules, RolesAllowedTo } from '@src/Models/modules';
import { Roles } from '@src/Models/roles';

import apiRequest from './api';
import { GetTableFilterProps } from './type';

export const getModules = async (): Promise<{ data: Modules[] }> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.MODULES}`,
  });
};

export const createRoles = async (data: {
  role_name: string;
  role_description: string;
  allowed_to: RolesAllowedTo[];
}) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.ROLES}`,
    data,
  });
};

export const editRoles = (id: string, data: { allowed_to: RolesAllowedTo[] }) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.ROLES}/${id}`,
    data,
  });
};

export const getRoles = async ({
  limit,
  page,
  search,
}: GetTableFilterProps): Promise<{ data: Roles[]; total: number }> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.ROLES}`,
    config: { params: { limit, page, search } },
  });
};

export const getRoleById = async (id: string): Promise<{ data: Roles }> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.ROLES}/${id}`,
  });
};
