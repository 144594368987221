import { useContext } from 'react';

import { Alert, Snackbar } from '@mui/material';

import { GlobalUIContext } from '@src/contexts/GlobalUIContext';

export const SnackbarProvider = () => {
  const { setSnackValue, snackValue } = useContext(GlobalUIContext);

  const handleClose = () => {
    setSnackValue(undefined);
  };

  return (
    <Snackbar open={!!snackValue} autoHideDuration={2000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={snackValue?.type} variant='filled' sx={{ width: '100%' }}>
        {snackValue?.message}
      </Alert>
    </Snackbar>
  );
};
