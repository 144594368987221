import { lazy } from 'react';

import { MerchandiseMasterSubCategory, MerchandiseSubRoutes, RouteActions, Routes } from '@src/lib/constants';
import { getRoutesConfig } from '@src/lib/helper';
import { RoutesConfigType } from '@src/lib/types';

const ProductsListing = lazy(() => import('@src/screens/Merchandise/Products'));
const Category = lazy(() => import('@src/screens/Merchandise/Category'));
const ProductsCreate = lazy(() => import('@src/screens/Merchandise/Products/CreateEditLayout'));
const ProductsEdit = lazy(() => import('@src/screens/Merchandise/Products/CreateEditLayout/EditLayout'));
const ProductsDetailView = lazy(() => import('@src/screens/Merchandise/Products/detailView'));
const MerchandiseDashboard = lazy(() => import('@src/screens/Merchandise/DashBoard'));
const MerchandiseProductDetail = lazy(() => import('@src/screens/Merchandise/Store/ProductDetail'));
const MerchandiseCart = lazy(() => import('@src/screens/Merchandise/Cart'));
const MerchandiseMaster = lazy(() => import('@src/screens/Merchandise/Master'));
const MerchandiseCreateEdit = lazy(() => import('@src/screens/Merchandise/Master/ProductCategories/CreateEditLayout'));
const MerchandiseAttributeCreate = lazy(() => import('@src/screens/Merchandise/Master/Attributes/CreateLayout'));
const MerchandiseAttributeEdit = lazy(() => import('@src/screens/Merchandise/Master/Attributes/EditLayout'));
const MerchandiseAttributeListing = lazy(() => import('@src/screens/Merchandise/Master/Attributes'));

const merchandiseRoutes: Array<RoutesConfigType> = [
  ...getRoutesConfig([
    {
      path: Routes.MERCHANDISE + MerchandiseSubRoutes.PRODUCTS,
      listing: ProductsListing,
      view: ProductsDetailView,
    },
    {
      path: Routes.MERCHANDISE + MerchandiseSubRoutes.MASTER + MerchandiseMasterSubCategory.CATEGORY,
      listing: MerchandiseMaster,
      createEdit: MerchandiseCreateEdit,
    },
  ]),
  {
    path:
      Routes.MERCHANDISE + MerchandiseSubRoutes.MASTER + MerchandiseMasterSubCategory.ATTRIBUTES + RouteActions.CREATE,
    component: MerchandiseAttributeCreate,
    layout: 'private',
  },
  {
    path:
      Routes.MERCHANDISE +
      MerchandiseSubRoutes.MASTER +
      MerchandiseMasterSubCategory.ATTRIBUTES +
      RouteActions.EDIT +
      '/:id',
    component: MerchandiseAttributeEdit,
    layout: 'private',
  },
  {
    path: Routes.MERCHANDISE + MerchandiseSubRoutes.MASTER + MerchandiseMasterSubCategory.ATTRIBUTES,
    component: MerchandiseAttributeListing,
    layout: 'private',
  },
  {
    path: Routes.MERCHANDISE + MerchandiseSubRoutes.DASHBOARD,
    component: MerchandiseDashboard,
    isBreadcrumb: false,
    hasMerchandiseSubHeader: true,
    layout: 'private',
  },
  {
    path: Routes.MERCHANDISE + MerchandiseSubRoutes.STORE,
    layout: 'private',
    component: Category,
    isBreadcrumb: false,
    hasMerchandiseSubHeader: true,
  },
  {
    path: Routes.MERCHANDISE + MerchandiseSubRoutes.STORE + '/:id',
    component: MerchandiseProductDetail,
    isBreadcrumb: false,
    hasMerchandiseSubHeader: true,
    layout: 'private',
  },
  {
    path: Routes.MERCHANDISE + MerchandiseSubRoutes.CART,
    component: MerchandiseCart,
    isBreadcrumb: false,
    hasMerchandiseSubHeader: true,
    layout: 'private',
  },
  {
    path: Routes.MERCHANDISE + MerchandiseSubRoutes.PRODUCTS + RouteActions.EDIT + '/:id',
    component: ProductsEdit,
    layout: 'private',
  },
  {
    path: Routes.MERCHANDISE + MerchandiseSubRoutes.PRODUCTS + RouteActions.CREATE,
    component: ProductsCreate,
    layout: 'private',
  },
];

export default merchandiseRoutes;
