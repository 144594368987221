import {
  ApprovalRulesSubCatergory,
  AsaMasterSubCategory,
  AsaSubRoutes,
  MerchandiseMasterSubCategory,
  MerchandiseSubRoutes,
  PoMasterSubCategory,
  PoSubRoutes,
  Routes,
} from '@src/lib/constants';

export const redirectionRoutes = [
  { route: Routes.ASA + AsaSubRoutes.MASTER, redirectionRoute: AsaMasterSubCategory.SESSION },
  { route: Routes.PO + PoSubRoutes.MASTER, redirectionRoute: PoMasterSubCategory.CATEGORY },
  { route: Routes.PO + PoSubRoutes.APPROVAL_RULES, redirectionRoute: ApprovalRulesSubCatergory.USERS },
  {
    route: Routes.MERCHANDISE + MerchandiseSubRoutes.MASTER,
    redirectionRoute: MerchandiseMasterSubCategory.CATEGORY,
  },
];
